<template>
  <div class="result">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>#{{ job.id }} {{ job.cmd }}: {{ job.name }}</v-card-title>
      <v-card-subtitle>
        <span v-if="job.retval" class="pa-1 red white--text">Error</span>
        <span v-else-if="job.finished_at" class="pa-1 green white--text">Complete</span>
        <span v-else class="pa-1 orange white--text">Working</span>
        Created: {{ job.created_at}} /
        <span v-if="job.finished_at">
          Finished: {{ job.finished_at }}
        </span>
        <span v-else>
          Status: <span class="orange">Working</span>
        </span>
        <v-dialog
          v-model="share_dialog"
          width="500"
          v-if="$route.params.id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="job.share_id ? 'primary' : 'secondary'"
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Share result
            </v-card-title>
            <v-card-text>
              <v-textarea
              id="share_url"
              outlined
              readonly
              :value="share_url"
              ></v-textarea>
              <div>
                結果ページを他の人と共有します。<br/>
                'NEW URL'で、新しいURLを生成します。このURLを知った人は、システムにログインしなくとも、結果ページにアクセスできます。
                既にURLが生成されている時に、新しいURLを生成すると、古いURLは無効になります。<br/>
                'RESET'で、共有を停止します。<br/>
                'COPY'で、URLをクリップボードにコピーします。
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="create_share_id">
                New URL
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="delete_share">
                Reset
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="copy_url">
                Copy
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="share_dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-subtitle>
      <v-expansion-panels v-model="expansion" multiple>
        <v-expansion-panel v-if="$route.params.id">
          <v-expansion-panel-header>Log</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea id="log_textarea" readonly outlined no-resize rows="10" row-height="10" :value="log"></v-textarea>
            <v-fab-transition>
              <v-btn  v-if="! job.finished_at" color='pink' fab small dark style="position: absolute; bottom: 60px; right: 50px;" @click.stop="get_job()">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="job.finished_at && $route.params.id">
          <v-expansion-panel-header>Files</v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li v-for="file in files" :key="file.name">
                <a :href='$backEnd + "/api/v1/files/" + $route.params.id + "/" + file.name' :download="file.dl_name" target="_blank">
                  {{ file.dl_name }}
                </a>
                ({{ $util.num_format(file.size, 0) }} Bytes)
                <span v-if="! file.dl">
                  <a :href='$backEnd + "/api/v1/files/" + $route.params.id + "/" + file.name' target="_blank" style="text-decoration: none;">
                    <v-btn icon><v-icon color="primary">mdi-shape-rectangle-plus</v-icon></v-btn>
                  </a>
                </span>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
      <div v-if="job.finished_at && job.retval == 0">
        <LdaResult v-if="job.cmd == 'lda'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></LdaResult>
        <Doc2VecResult v-else-if="job.cmd == 'doc2vec'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></Doc2VecResult>
        <kShapeResult v-else-if="job.cmd == 'kshape'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></kShapeResult>
        <SHAPResult v-else-if="job.cmd == 'shap'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></SHAPResult>
        <SHAPTSResult v-else-if="job.cmd == 'shapts'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></SHAPTSResult>
        <XaiEli5PdpboxResult v-else-if="job.cmd == 'xai_eli5pdpbox'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></XaiEli5PdpboxResult>
        <XaiSkaterResult v-else-if="job.cmd == 'xai_skater'" v-bind:id="$route.params.id || $route.params.share_id" v-bind:job="job"></XaiSkaterResult>
      </div>
    </v-card>
  </div>
</template>

<script>
import LdaResult from '@/components/ldaresult'
import Doc2VecResult from '@/components/doc2vecresult'
import kShapeResult from '@/components/kshaperesult'
import SHAPResult from '@/components/shapresult'
import SHAPTSResult from '@/components/shaptsresult'
import XaiEli5PdpboxResult from '@/components/xai_eli5pdpbox_result'
import XaiSkaterResult from '@/components/xai_skater_result'
import router from '@/router'

export default {
  name: 'Result',
  components: {
    LdaResult,
    Doc2VecResult,
    kShapeResult,
    SHAPResult,
    SHAPTSResult,
    XaiEli5PdpboxResult,
    XaiSkaterResult
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: false,
      snackbarColor: null,
      share_dialog: null,
      job: {},
      files: {},
      log: '',
      result_data: null,
      expansion: [],
    }
  },
  mounted: function() {
    this.get_job();
  },
  computed: {
    share_url: function() {
      return this.job.share_id ? (window.location.origin + '/share/' + this.job.share_id) : ''
    }
  },
  methods: {
    get_job: function() {
      var id = this.$route.params.id;
      var share_id = this.$route.params.share_id;
      var self = this;
      self.loading = true;
      (async() => {
        var res = await self.axios.get(self.$backEnd + '/api/v1/job/get_job', id ? { params: {id: id} } : { params: { share_id: share_id }});
        if (res.data.result) {
          self.job = res.data.job;
          if (res.data.job.finished_at) {
            this.get_files();
            this.expansion = [];
          }
          else {
            this.expansion = [0];
          }
          this.get_log();
          self.loading = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        if (id) {
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        }
        else {
          router.push(this.$route.query.redirect || '/404');
        }
      });
    },
    get_files: function() {
      var id = this.$route.params.id;
      if (id) {
        var self = this;
        self.loading = true;
        (async() => {
          var res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id);
          if (res.data.result) {
            self.files = res.data.files;
            self.loading = false;
          }
          else {
            self.snackbarText = res.data.message;
            self.snackbar = true;
            self.loading = false;
          }
        })().catch((e) => {
          console.log("caught exception: " + e);
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        });
      }
    },
    get_log: function() {
      var id = this.$route.params.id;
      if (id) {
        var self = this;
        self.loading = true;
        (async() => {
          var res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.log');
          if (res.status == 200) {
            self.log = res.data;
            var elm = document.getElementById('log_textarea');
            if (elm) {
              elm.scrollTop = elm.scrollHeight;
            }
          }
          else {
            self.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
            self.snackbar = true;
            self.loading = false;
          }
          self.loading = false;
        })().catch((e) => {
          console.log("caught exception: " + e);
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        });
      }
    },
    create_share_id: function() {
      var id = this.$route.params.id;
      if (id) {
        var self = this;
        self.loading = true;
        (async() => {
          var res = await self.axios.get(self.$backEnd + '/api/v1/job/create_share_id', { params: {id: id} });
          if (res.status == 200) {
            self.job.share_id = res.data.share_id;
          }
          else {
            self.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
            self.snackbar = true;
            self.loading = false;
          }
          self.loading = false;
        })().catch((e) => {
          console.log("caught exception: " + e);
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        });
      }
    },
    delete_share: function() {
      var id = this.$route.params.id;
      if (id) {
        var self = this;
        self.loading = true;
        (async() => {
          var res = await self.axios.get(self.$backEnd + '/api/v1/job/delete_share', { params: {id: id} });
          if (res.status == 200) {
            self.job.share_id = res.data.share_id;
          }
          else {
            self.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
            self.snackbar = true;
            self.loading = false;
          }
          self.loading = false;
        })().catch((e) => {
          console.log("caught exception: " + e);
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        });
      }
    },
    copy_url: function() {
      var elm = document.getElementById('share_url');
      elm.select();
      document.execCommand('copy');
      return;
    }
  }
}
</script>
