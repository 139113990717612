import Vue from 'vue'
import VueRouter from 'vue-router'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Config from '@/views/Config.vue'
import Analysis from '@/views/Analysis.vue'
import Result from '@/views/Result.vue'
import NotFound from '@/views/NotFound.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  { path: '/',      name: 'Home',   component: Home, meta: { title: 'Home'} },
  { path: '/login', name: 'Login',  component: Login, meta: { isPublic: true } },
  { path: '/analysis',  name: 'Analysis',   component: Analysis,  meta: { title: 'Analysis' } },
  { path: '/config',  name: 'Config',   component: Config,  meta: { title: 'Config' } },
  { path: '/result/:id',  name: 'Result',   component: Result,  meta: { title: 'Result' } },
  { path: '/share/:share_id', name: 'Share', component: Result, meta: { title: 'Share', isPublic: true } },
  { path: '*', name: 'NotFound',  component: NotFound, meta: { title: '404', isPublic: true } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.isPublic) && ! (store.getters.is_login) ) {
    next({ path: '/login', query: { redirect: to.fullPath }});
  } else {
    next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = 'Twigather ' + to.meta.title;
    store.commit('set_menu', to.meta.title);
  }
  else {
    document.title = 'Twigather';
    store.commit('set_menu', '');
  }
});

export default router
