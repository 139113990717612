<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="is_login">
      <v-app-bar-nav-icon @click.stop="togglemenu"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ menu_item ? menu_item : '' }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title><v-icon>mdi-twitter</v-icon>Twigather {{ is_login ? (' / ' + login_name) : '' }}</v-toolbar-title>
    </v-app-bar>

    <v-main style="position: relative;">
      <v-navigation-drawer v-model="is_disp" absolute temporary v-if="is_login">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Twigather
              <span class="text-caption ml-2">Ver.{{ version }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              ツイート収集システム
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item @click.stop="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-list-item-subtitle>
                Copyright ©︎ Prof Yukari Shirota<br/>
                @Gakushuin University
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'

export default {
  data () {
    return {
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard', to: '/' },
        { title: 'Analysis', icon: 'mdi-flask-outline', to: '/analysis' },
        { title: 'Config', icon: 'mdi-cog-outline', to: '/config' }
      ],
      version: ''
    }
  },
  created() {
      const self = this;
      if (this.$withoutServer) {
        this.version = 'standalone';
        return;
      }
      (async() => {
        var res = await self.axios.get(self.$backEnd + '/api/v1/get_version');
        if (res.data.version) {
          this.version = res.data.version
        }
        if (res.data.is_login) {
          this.$store.dispatch('login', res.data.username);
        }
        else {
          this.$store.dispatch('logiout');
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
      });
  },
  computed: {
    is_disp : {
      get: function() { return this.$store.getters.menu_disp },
      set: function(x) {
        if (x) {
          this.$store.dispatch('display_menu');
        }
        else {
          this.$store.dispatch('hide_menu');
        }
      }
    },
    is_login() {
      var is_public = this.$route.meta && this.$route.meta.isPublic;
      return this.$store.getters.is_login && (! is_public);
    },
    menu_item() { return this.$store.getters.menu_item },
    login_name() { return this.$store.getters.loginname }
  },
  methods: {
    togglemenu() {
      if (this.is_login) {
        this.is_disp = ! this.is_disp;
      }
    },
    logout() {
      this.$store.dispatch('logout');
      Cookies.set('login', false, {SameSite: 'strict'});
      this.axios.get(this.$backEnd + '/api/v1/logout');
      router.push('/login');
    }
  }
}
</script>
