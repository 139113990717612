<script>
import Chart from 'chart.js'
import { Bar, mixins } from 'vue-chartjs'
import zoom from 'chartjs-plugin-zoom';
const { reactiveProp } = mixins

export default {
  extends: Bar,
  name: 'BarChart',
  mixins: [reactiveProp],
  props: ['options'],
  mounted() {
    Chart.defaults.global.defaultFontColor = '#000';
    this.addPlugin(zoom);
    this.renderChart(this.chartData, this.options);
    var self = this;
    this.$data._chart.canvas.addEventListener('dblclick', function() {
      self.$data._chart.resetZoom();
    });
  },
  methods: {
    update: function() {
      this.$data._chart.update();
    }
  }
}
</script>
