<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-slider v-model="value.vectors" label="Vectors / 次元" min="4" max="100">
          <template v-slot:append>
            <v-text-field
              v-model="value.vectors"
              class="mt-0 pt-0"
              min="2"
              max="20"
              hide-details
              single-line
              type="number"
              style="width: 60px"></v-text-field>
          </template>
        </v-slider>
      </v-col>
      <v-col cols="6">
        <v-slider v-model="value.clusters" label="Clustres / クラスタ数" min="2" max="20">
          <template v-slot:append>
            <v-text-field
              v-model="value.clusters"
              class="mt-0 pt-0"
              min="2"
              max="20"
              hide-details
              single-line
              type="number"
              style="width: 60px"></v-text-field>
          </template>
        </v-slider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
function defaults() {
  return {
    vectors: 30,
    clusters: 5,
  }
}
export default {
  props: {
    value: {
      type: Object,
      require: false,
      default: () => defaults()
    }
  },
  data() {
    return {
    };
  },
  mounted: function() {
    var d = defaults();
    Object.keys(d).forEach(k => { this.value[k] = d[k]});
  },
  computed: {
  },
  methods: {
  }
}
</script>