<template>
  <v-container>
    <v-row>
      <v-col>
        <span>Method</span>
        <v-radio-group v-model="value.method" mandatory row>
          <v-radio label="k-Shape" value="kshape"></v-radio>
          <v-radio label="k-Means" value="kmeans"></v-radio>
          <v-radio label="PreComputed" value="precomputed"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <span>Projection</span>
        <v-radio-group v-model="value.dr" mandatory row>
          <v-radio label="t-SNE" value="tsne"></v-radio>
          <v-radio label="UMAP" value="umap"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="value.method == 'kmeans'">
        <span>Metric</span>
        <v-radio-group v-model="value.metric" mandatory row>
          <v-radio label="Euclidean" value="euclidean"></v-radio>
          <v-radio label="DTW" value="dtw"></v-radio>
          <v-radio label="SoftDTW" value="softdtw"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="value.method != 'precomputed'">
        <v-checkbox v-model="value.normalize" label="Normalize"/>
      </v-col>
    </v-row>

    <v-row v-if="value.method != 'precomputed'">
      <v-col cols="8">
        <v-slider v-model="value.cluster" label="Cluster num / クラスタ数"
          hint="If set to 0, it is determined by the elbow method."
          persistent-hint
          min="0" max="20">
          <template v-slot:append>
            <v-text-field
              v-model="value.cluster"
              class="mt-0 pt-0"
              min="0"
              max="20"
              hide-details
              single-line
              type="number"
              style="width: 60px"></v-text-field>
          </template>
        </v-slider>
      </v-col>
      <v-col cols="4">
        <v-select v-model="value.period" :items="period_items" label="Period / 期間"></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
function defaults() {
  return {
    cluster: 0,
    period: 'm',
    method: 'kshape',
    metric: 'euclidean',
    dr: 'tsne',
    normalize: true
  }
}
export default {
  props: {
    value: {
      type: Object,
      require: false,
      default: () => defaults()
    }
  },
  data() {
    return {
      period_items: [
        { text: 'month', value: 'm' },
        { text: '2 month', value: '2m' },
        { text: '3 month', value: '3m' },
        { text: '4 month', value: '4m' },
        { text: '6 month', value: '6m' },
        { text: '12 month', value: '6m' },
        { text: 'week', value: 'week' },
        { text: '2 week', value: '2w' },
        { text: '3 week', value: '3w' },
        { text: '4 week', value: '4w' },
        { text: '5 week', value: '5w' },
        { text: '5 day', value: '5' },
        { text: '10 day', value: '10' },
        { text: '15 day', value: '15' },
        { text: '20 day', value: '20' },
        { text: '50 day', value: '50' },
        { text: 'all', value: '0' }
      ]
    };
  },
  mounted: function() {
    var d = defaults();
    Object.keys(d).forEach(k => { this.value[k] = d[k]});
  },
  computed: {
  },
  methods: {
  }
}
</script>
