<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-slider v-model="value.topicnum" label="Topics / トピック数" min="2" max="20">
          <template v-slot:append>
            <v-text-field
              v-model="value.topicnum"
              class="mt-0 pt-0"
              min="2"
              max="20"
              hide-details
              single-line
              type="number"
              style="width: 60px"></v-text-field>
          </template>
        </v-slider>
      </v-col>
      <v-col cols="4">
        <v-select v-model="value.period" :items="period_items" label="Period / 期間"></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
         <v-text-field v-model="value.no_below" type="number" label="No below" min="0" hint="Filter out token that appear in less than no_below documents. / 少数の文書にしか現れない語を削除します。"></v-text-field>
      </v-col>
      <v-col>
         <v-text-field v-model="value.no_above" type="number" step="0.1" min="0" max="1.0" label="No above" hint="Filter out token that appear in more than no_above documents (fraction of total corpus size, not absolute number). / 多くの文書に現れる語を削除します。"></v-text-field>
      </v-col>
      <v-col>
        <v-radio-group v-model="value.tfidf" mandatory row>
          <v-radio label="BoW" :value="false"></v-radio>
          <v-radio label="TF-IDF" :value="true"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
function defaults() {
  return {
    topicnum: 5,
    no_below: 5,
    no_above: 0.5,
    tfidf: true,
    period: 'day'
  }
}
export default {
  props: {
    value: {
      type: Object,
      require: false,
      default: () => defaults()
    }
  },
  data() {
    return {
      period_items: [
        { text: '--', value: null },
        { text: 'year', value: 'year' },
        { text: 'month', value: 'month' },
        { text: 'week', value: 'week' },
        { text: 'day', value: 'day' },
        { text: 'hour', value: 'hour' }
      ]
    };
  },
  mounted: function() {
    var d = defaults();
    Object.keys(d).forEach(k => { this.value[k] = d[k]});
  },
  computed: {
  },
  methods: {
  }
}
</script>