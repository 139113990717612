<template>
  <div name="ldaresult">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <div class="text-caption text-center">
      {{ loading_text ? loading_text : ''}}
      </div>
    </v-overlay>

    <v-card-subtitle>Options</v-card-subtitle>
    <v-card-text v-if="opts.opts">
      <div class="ml-6 body-2">
        <span class="font-weight-bold">Period:</span><span class="mr-4">{{ opts.opts.period ? opts.opts.period : 'None' }}</span>
        <span class="font-weight-bold">Topics:</span><span class="mr-4">{{ opts.opts.topicnum }}</span>
        <span class="font-weight-bold">Vectorize:</span><span class="mr-4">{{ opts.opts.tfidf ? 'TF-IDF' : 'bag-of-words' }}</span>
        <span class="font-weight-bold">No Above:</span><span class="mr-4">{{ opts.opts.no_above }}</span>
        <span class="font-weight-bold">No below:</span><span class="mr-4">{{ opts.opts.no_below }}</span>
      </div>
      <TokenizerOpts v-bind:opts="opts.tokenizer_opts"></TokenizerOpts>
    </v-card-text>

    <v-card-subtitle>Results</v-card-subtitle>
    <v-card-text>
      <v-list-item v-for="(item, index) in word_topic" :key="item.str" two-line>
        <v-list-item-content>
          <v-list-item-title>
            Topic {{index+1}}
            <v-btn icon color="primary" class="mr-2" @click="display_wordclowd(index)">
              <v-icon>mdi-magnify-plus</v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.str }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div v-if="line_chart_data">
        <line-chart :chart-data="line_chart_data" :options="line_chart_options" :height="chart_height"/>
      </div>

      <v-dialog v-model="wordcloud" persistent>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Topic {{topic_id + 1}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="wordcloud = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
              <div class="d-flex justify-center">
                <cloud :data="wordcloud_data" :fontSizeMapper="fontSizeMapper" :rotate="CloudRotate" />
              </div>
              <div class="d-flex justify-end" v-if="word_topic && (topic_id !== null)">
                  {{ word_topic[topic_id].str }}
              </div>
              <v-data-table
                dense
                :headers="word_table_headers"
                :items="word_table"
                item-key="word"
                class="elevation-1"
              ></v-data-table>
            </v-card-text>
          </v-card>
      </v-dialog>
    </v-card-text>

  </div>
</template>

<script>
import LineChart from '@/components/LineChart'
import TokenizerOpts from '@/components/tokenizeropts'
import Cloud from 'vue-d3-cloud'

export default {
  name: 'LdaResult',
  components: {
    LineChart,
    TokenizerOpts,
    Cloud
  },
  props: {
    id: String,
    job: Object
  },
  data() {
    return {
      loading: false,
      opts: {},
      loading_text: null,
      line_chart_data: null,
      line_chart_options: {
        responsive: true, 
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                enabled: true,
                mode: 'x'
            }
          }
        }
      },
      snackbar: null,
      snackbarText: null,
      snackbarColor: null,
      word_topic: null,
      chart_height: 400,
      wordcloud: false,
      wordcloud_data: [],
      topic_id: null,
      word_table: [],
      word_table_headers: [
        { text: 'word', align: 'start', value: 'word' },
        { text: 'value', align: 'start', value: 'value' }
      ],
    }
  },
  mounted: function() {
    this.opts = JSON.parse(this.job.opts);
    this.update();
  },
  methods: {
    update: async function() {
      var id = this.id;
      var self = this;
      self.loading = true;
      self.loading_text = "Loading";
      try {
        var res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.json');
        if (res.status == 200) {
          self.result_data = res.data;
          self.word_topic = res.data.word_topic;
          self.loading_text = "Plotting";
          this.draw_graph(res.data);
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
        }
        self.loading = false;
        self.loading_text = null;
        self.loading = false;
      }
      catch(e) {
        console.log(e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
        self.loading = false;
        self.loading_text = null;
      }
    },
    draw_graph: function(data) {
      if (data.period_topic) {
        var datasets = [];
        data.word_topic.forEach((e, i) => {
          var rgb = this.$util.index_color(i, data.word_topic.length, 0.5);
          datasets.push({ type: 'line', fill: false, lineTension: 0,
            label: 'Topic ' + String(i + 1), data: [],
            borderColor: 'rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ', 1.0)'
          });
        });
        var labels = []
        data.period_topic.forEach((e) => {
          labels.push(e.period);
          e.arr.forEach((d, i) => {
            datasets[i].data.push(d);
          });
        });
        this.line_chart_data = { 'labels': labels, 'datasets': datasets };
      }
    },
    display_wordclowd: function(id) {
      this.wordcloud = true;
      this.topic_id = id;
      this.wordcloud_data = [];
      this.word_table = []
      this.word_topic[id].words.forEach((e) => {
        this.wordcloud_data.push({'text': e[0], 'value': e[1] * 100000});
        this.word_table.push({'word': e[0], 'value': e[1]});
      });
    },
    fontSizeMapper: function(word) {
      return Math.log2(word.value) * 5;
    },
    // eslint-disable-next-line
    CloudRotate: function(word) {
      return (~~(Math.random() * 6) - 3) * 30;
    }
  }
}
</script>