const state = {
    jobs_options: {}
}

const getters = {
    jobs_options: state => state.jobs_options
}

const actions = {
    set_jobs_option({ commit }, options) {
        commit('set_jobs_option', options);
    }
}
  
const mutations = {
    set_jobs_option(state, options) {
        state.jobs_options = options;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}