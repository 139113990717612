<template>
  <v-container>
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Twigather</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                id="username"
                label="username / ユーザー名"
                name="username"
                prepend-icon="mdi-account"
                type="text"
                v-model="username"
                :rules="[rules.required]"
              />

              <v-text-field
                id="password"
                label="password / パスワード"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
                :rules="[rules.required]"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="login" color="primary">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'

export default {
  data () {
    return {
      snackbar: false,
      signinError: false,
      snackbarText: '',
      snackbarColor: '',
      username: "",
      password: "",
      rules: {
        required: v => !!v || 'Required field.',
      }
    }
  },
  mountd: function() {
    if (this.$store.getter.is_login) {
      router.push(this.$route.query.redirect || '/');
    }
  },
  methods: {
    login: function() {
      this.snackbar = false;
      this.signinError = false;
      if (! this.$refs.form.validate()) {
        return;
      }
      const self = this;
      if (this.$withoutServer) {
        this.$store.dispatch('login', this.username)
        this.$store.dispatch('display_menu');
        Cookies.set('login', this.username, { SameSite: 'strict' });
        router.push(this.$route.query.redirect || '/');
        return;
      }
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/login', { username: self.username, password: self.password });
        if (res.data.username) {
          self.$store.dispatch('login', res.data.username)
          self.$store.dispatch('display_menu');
          Cookies.set('login', res.data.username, { SameSite: 'strict' });
          router.push(this.$route.query.redirect || '/');
        }
        else {
          self.snackbarText = 'Incorrect username or password.';
          self.snackbar = true;
          self.signinError = true;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.signinError = true;
      });
    }
  }
}
</script>
