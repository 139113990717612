<template>
  <div name="doc2vecresult" class="px-2">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      >{{ loading_percent ? loading_percent + '%' : '' }}</v-progress-circular>
      <div class="text-caption text-center">
      {{ loading_text ? loading_text : ''}}
      </div>
    </v-overlay>

    <v-card-subtitle>Options</v-card-subtitle>
    <v-card-text v-if="opts.opts">
      <div class="ml-6 body-2">
        <span class="font-weight-bold">Clustres:</span><span class="mr-4">{{ opts.opts.clusters }}</span>
        <span class="font-weight-bold">Vectors:</span><span class="mr-4">{{ opts.opts.vectors }}</span>
      </div>
      <TokenizerOpts v-bind:opts="opts.tokenizer_opts"></TokenizerOpts>
    </v-card-text>

    <v-card-subtitle>Results</v-card-subtitle>
    <v-card-text>
      <h4>Number of tweets in the cluster / day</h4>
      <div v-if="line_chart_data">
        <line-chart :chart-data="line_chart_data" :options="line_chart_options" :height="line_chart_height"/>
      </div>

      <br/>
      <br/>

      <h4>Tweets scatter chart</h4>
      <div v-if="scatter_chart_data" style="position: relative;">
        <scatter-chart id="scatter_chart" :chart-data="scatter_chart_data" :options="scatter_chart_options" :height="scatter_chart_height"/>
        <div id="scatter-chart-tooltip" :class="$style.scatter_tooltip"></div>
        </div>
      <div>
        <v-row>
          <v-checkbox v-model="date_sync" label="Period fixed / 日数固定" @change="! date_sync || period_start()"></v-checkbox>
          <v-checkbox v-model="is_sampling" class="ml-6" label="Sampling / サンプリング" @change="is_sampling || period_change()"></v-checkbox>
        </v-row>
        <v-range-slider v-if="date_list.length"
          v-model="date_range" min="0" :max="date_list.length - 1"
          @start="period_start"
          @input="period_input"
          @change="period_change"
        >
          <template v-slot:prepend>
            <span style="white-space: nowrap;">{{ date_list[Math.min(date_range[0], date_range[1])] }}</span>
          </template>
          <template v-slot:append>
            <span style="white-space: nowrap;">{{ date_list[Math.max(date_range[0], date_range[1])] }}</span>
          </template>
        </v-range-slider>
      </div>

      <v-dialog v-model="toomany_dialog" persistent max-width="480">
        <v-card>
          <v-card-title class="headline">
            Too many samples.<br/>サンプルが多すぎます
          </v-card-title>
          <v-card-text>
            The large number of samples may slow down your browser, do you want to plot them?<br/>
            サンプル数が多いため、ブラウザの動作が遅くなる場合がありますが、このままプロットしますか？
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text outlined @click="toomany_dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" text outlined @click="update_graph(true); toomany_dialog = false">
              Sampling
            </v-btn>
            <v-btn color="primary" text outlined @click="update_graph(false); toomany_dialog = false">
              Plot
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>

  </div>
</template>

<script>
import ScatterChart from '@/components/ScatterChart'
import TokenizerOpts from '@/components/tokenizeropts'
import LineChart from '@/components/LineChart'

export default {
  name: 'Doc2VecResult',
  components: {
    ScatterChart,
    TokenizerOpts,
    LineChart
  },
  props: {
    id: String,
    job: Object
  },
  data() {
    return {
      opts: {},
      display_limit: 3000,
      loading: false,
      loading_text: null,
      loading_percent: null,
      snackbar: null,
      snackbarText: null,
      snackbarColor: null,
      scatter_chart_data: null,
      scatter_chart_height: 480,
      scatter_chart_options: {
        responsive: true, 
        animation: {
          duration: 0
        },
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'xy'
            },
            zoom: {
                enabled: true,
                mode: 'xy'
            }
          }
        },
        tooltips: {
          enabled: false,
          custom: this.scatter_custom_callback,
        },
        legend: {
          display: false
        }
      },
      line_chart_data: null,
      line_chart_plotting: false,
      line_chart_height: 300,
      line_chart_options: {
        responsive: true, 
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                enabled: true,
                mode: 'x'
            }
          }
        }
      },
      date_range: [0, 0],
      date_range_bak: [0, 0],
      date_diff: null,
      date_sync: false,
      graph_data: null,           // 取得したJSON
      date_list: [],              // 'YYYY-MM-DD' のリスト
      cluster_periodic: {},       // 'YYYY-MM-DD' 毎の各クラスタ件数のリスト
      period_clusters: [],        // period_clusters[period][cluster] に グラフ描画用の配列を格納したもの
      toomany_dialog: null,
      is_sampling: true
    }
  },
  mounted: async function() {
    this.opts = JSON.parse(this.job.opts);
    this.graph_data = await this.get_data();
    if (this.graph_data) {
      await this.make_grapth_data();
      this.date_range = [0, this.date_list.length - 1];
      this.period_start();
      await this.draw_scatter(0, this.date_list.length - 1, true);
    }
  },
  methods: {
    // 期間のツイート数を数える
    count_tweets: function(start, end) {
      let count = 0;
      for (let i = start; i <= end; i++) {
        count += this.cluster_periodic[i].count;
      }
      return count;
    },
    // 期間入力の日数ロック
    period_start: function() {
      if (this.date_range[0] > this.date_range[1]) {
        var t = this.date_range[1];
        this.date_range[1] = this.date_range[0];
        this.date_range[0] = t;
      } 
      this.date_diff = this.date_range[1] - this.date_range[0];
      this.date_range_bak[0] = this.date_range[0];
      this.date_range_bak[1] = this.date_range[1];
      return true;
    },
    period_input: function() {
      if ((this.date_sync) && (this.date_diff !== null)) {
        if (this.date_range[0] != this.date_range_bak[0]) {
          this.date_range[1] = this.date_range[0] + this.date_diff;
          if (this.date_range[1] > this.date_list.length - 1) {
            this.date_range[1] = this.date_list.length - 1;
            this.date_range[0] = this.date_range[1] - this.date_diff;
          }
        }
        else if (this.date_range[1] != this.date_range_bak[1])  {
          this.date_range[0] = this.date_range[1] - this.date_diff;
          if (this.date_range[0] < 0) {
            this.date_range[0] = 0;
            this.date_range[1] = this.date_diff;
          }
        }
      }
      this.date_range_bak[0] = this.date_range[0];
      this.date_range_bak[1] = this.date_range[1];
      return true;
    },
    period_change: async function() {
      let start = Math.min(this.date_range[0], this.date_range[1]);
      let end = Math.max(this.date_range[0], this.date_range[1]);
      let count = this.count_tweets(start, end);
      if (count > this.display_limit) {
        if (this.is_sampling) {
          this.draw_scatter(start, end, true);
        }
        else {
          this.toomany_dialog = true;
        }
      }
      else {
        this.draw_scatter(start, end);
      }
    },
    update_graph: async function(sample=false) {
      let start = Math.min(this.date_range[0], this.date_range[1]);
      let end = Math.max(this.date_range[0], this.date_range[1]);
      this.draw_scatter(start, end, sample);
    },
    get_data: async function() {
      var id = this.id;
      this.loading = true;
      this.loading_text = "Loading";
      var self = this;
      let res;
      try {
        res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.json', {
          transformResponse: [function (data) {
            return data;
          }],
          responseType: 'text',
          onDownloadProgress: async (progressEvent) => {
            if (progressEvent.total) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              self.loading_percent = percentCompleted;
            }
          }
        })
      }
      catch(e) {
        this.snackbarText = res.data.message;
        this.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
      if (res.status == 200) {
        self.loading_percent = null;
        this.loading_text = "Parsing";
        let intid = setInterval(() => {this.loading_text += '.'}, 3000);
        let data = await new Promise(resolve => this.$worker.run((arg) => JSON.parse(arg), [res.data]).then(resolve));
        clearInterval(intid);
        this.loading_percent = null;
        this.loading = false;
        return data;
      }
      else {
        this.snackbarText = res.data.message;
        this.snackbar = true;
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
    },
    make_grapth_data: async function() {
      this.loading = true;
      this.loading_percent = null;
      this.loading_text = "Aggregating";
      await new Promise(resolve => this.$nextTick(resolve));
      await new Promise(resolve => setTimeout(resolve, 1));

      // クラスタの数を数える
      let cluster_max = 0;
      let clusters = this.graph_data.clusters;
      for (let i = 0, len = clusters.length; i < len; i = i + 1) {
        let element = clusters[i];
        let cluster = element.cluster;
        cluster_max = Math.max(cluster_max, cluster);
      }

      // 個々のツイートを期間とクラスタに分ける
      this.cluster_periodic = [];
      let lastdate = '';
      let ptr_cluster = null;
      let line_labels = [];
      // eslint-disable-next-line
      let line_counts = Array(cluster_max + 1).fill().map(e => []);
      for (let i = 0, len = clusters.length; i < len; i = i + 1) {
        let elm = this.graph_data.clusters[i]
        let date = this.graph_data.texts[i].date.substr(0, 10);
        if (lastdate != date) {
          // eslint-disable-next-line
          this.cluster_periodic.push({ date: date, count: 0, clusters: Array(cluster_max + 1).fill().map(e => []) });
          ptr_cluster = this.cluster_periodic[this.cluster_periodic.length - 1];
          line_labels.push(date);
          for (let j = 0; j <= cluster_max; j++) {
            line_counts[j].push(0);
          }
          lastdate = date;
        }
        ptr_cluster.clusters[elm.cluster].push({x: elm.arr[0], y: elm.arr[1], index: i});
        ptr_cluster.count++;
        line_counts[elm.cluster][this.cluster_periodic.length - 1]++;
        if (i % 1000 == 0) {
          this.loading_percent = Math.round(i*100 / len);
        }
        if (i % 100 == 0) {
          await new Promise(resolve => this.$nextTick(resolve));
          await new Promise(resolve => setTimeout(resolve, 1));
        }
      }
      this.loading_percent = null;

      // 日付リストの作成
      this.date_list = this.cluster_periodic.map((e) => e.date);

      // 日別データの構築と描画
      var line_datasets = [];
      for (let i = 0; i <= cluster_max; i++) {
        var rgb = this.$util.index_color(i, cluster_max + 1, 0.5);
        line_datasets.push({ type: 'line', fill: false, lineTension: 0,
          label: 'Cluster ' + String(i + 1), data: line_counts[i],
          borderColor: 'rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ', 1.0)'
        });
      }
      this.line_chart_data = { 'labels': this.date_list, 'datasets': line_datasets };
    },
    draw_scatter: async function(start, end, sample=false) {
      this.loading = true;
      this.loading_text = "Plotting";
      await new Promise(resolve => this.$nextTick(resolve));
      await new Promise(resolve => setTimeout(resolve, 50));

      let cluster_num = this.cluster_periodic[0].clusters.length;
      let datasets = [];
      for (let i = 0; i < cluster_num; i++) {
        let rgb = this.$util.index_color(i, cluster_num + 1);
        datasets.push({
          label: `Cluster ${i+1}`,
          borderColor: 'rgba(0,0,0,0)',
          backgroundColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.5)`,
          data: []
        });
      }
      let scatter_chart_data = { datasets: datasets };

      scatter_chart_data.datasets.forEach((e) => {
        e.data = [];
      });
      let drop_rate = 1.0;
      if (sample) {
        drop_rate = this.display_limit / this.count_tweets(start, end)
      }
      for (let i = start; i <= end; i++) {
        let e = this.cluster_periodic[i];
        for (let j = 0; j < cluster_num; j++) {
          if (sample) {
            // eslint-disable-next-line
            scatter_chart_data.datasets[j].data = datasets[j].data.concat(e.clusters[j].filter(_ => Math.random() < drop_rate))
          }
          else {
            scatter_chart_data.datasets[j].data = datasets[j].data.concat(e.clusters[j]);
          }
          await new Promise(resolve => this.$nextTick(resolve));
          await new Promise(resolve => setTimeout(resolve, 1));
        }
      }
      this.scatter_chart_data = scatter_chart_data;
      this.loading = false;
    },
    scatter_custom_callback: function(tooltipModel) {
      var tooltipEl = document.getElementById('scatter-chart-tooltip');
      if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
      }
      if (! tooltipModel.dataPoints) {
        return;
      }

      let index = this.scatter_chart_data.datasets[tooltipModel.dataPoints[0].datasetIndex].data[tooltipModel.dataPoints[0].index].index;
      let tweet = this.graph_data.texts[index];
      tooltipEl.innerHTML = `<div class="text-left subtitle-2">${tweet.date}</div>`
                          + `<div class="body-2">${tweet.text}</div>`;
      if (tweet.name) {
        tooltipEl.innerHTML += `<div class="text-right caption">${tweet.name}</div>`;
      }

      // Display, position, and set styles for font
      var canvas = document.querySelector('#scatter_chart canvas')
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      if (tooltipModel.caretX + tooltipEl.offsetWidth < canvas.offsetLeft + canvas.clientWidth) {
        tooltipEl.style.left = tooltipModel.caretX + 'px';
      }
      else {
        tooltipEl.style.left = tooltipModel.caretX - tooltipEl.offsetWidth + 'px';
      }
      tooltipEl.style.top = tooltipModel.caretY + 'px';
    }
  }
}
</script>

<style module>
.scatter_tooltip {
  position: absolute;
  text-align: left;
  width: 360px;
  padding: 4px;
  font: 12px sans-serif;
  background: #000000c0;
  color: #ffffffff;
  border: 10px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
}
</style>