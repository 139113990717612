<template>
  <div name="kshaperesult">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
            Training Result
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <img :src="$backEnd + '/api/v1/files/' + id + '/output_train.png'"/>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Surrogate Tree
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <img :src="$backEnd + '/api/v1/files/' + id + '/output_surrogate_tree.png'" class="resizeimg"/>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<style scoped>
.resizeimg {
  max-width: 90vw;
}
</style>

<script>

export default {
  name: 'XaiSkaterResult',
  components: {
  },
  props: {
    id: String,
    job: Object
  },
  data() {
    return {
      opts: {},
      loading: false,
      snackbar: false,
      snackbarText: false,
      snackbarColor: null,
      share_dialog: null,
      files: {},
      log: '',
      panel: [1],
      data: null,
    }
  },
  mounted: async function() {
    this.opts = JSON.parse(this.job.opts);
    this.data = await this.get_data();
  },
  computed: {
    share_url: function() {
      return this.job.share_id ? (window.location.origin + '/share/' + this.job.share_id) : ''
    }
  },
  watch: {
    panel: function(n, o) {
      if ((n.includes(0)) && (! o.includes(0))) {
        //this.$refs.scatter.redraw();
      }
    }

  },
  methods: {
    update_features: function() {
      var cols = this.shap_data.feature_cols;
      this.features = [];
      var index = 0;
      for (var i = 0; i < cols.length - 1; i++) {
        for (var j = i + 1; j < cols.length; j++) {
          this.features.push({
            index: index++,
            x: i, y: j,
            xcol: cols[i], ycol: cols[j],
            name: cols[i] + '_' + cols[j],
            text: cols[i] + ' - ' + cols[j]
          })
        }
      }
    },
    get_data: async function() {
      var id = this.id;
      this.loading = true;
      this.loading_text = "Loading";
      var self = this;
      let res;
      try {
        res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.json', {
          transformResponse: [function (data) {
            return data;
          }],
          responseType: 'text',
          onDownloadProgress: async (progressEvent) => {
            if (progressEvent.total) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              self.loading_percent = percentCompleted;
            }
          }
        })
      }
      catch(e) {
        this.snackbarText = res.data.message;
        this.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
      if (res.status == 200) {
        self.loading_percent = null;
        this.loading_text = "Parsing";
        let intid = setInterval(() => {this.loading_text += '.'}, 3000);
        let data = await new Promise(resolve => this.$worker.run((arg) => JSON.parse(arg), [res.data]).then(resolve));
        clearInterval(intid);
        this.loading_percent = null;
        this.loading = false;
        return data;
      }
      else {
        this.snackbarText = res.data.message;
        this.snackbar = true;
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
    }
  }
}
</script>
