<template>
  <div class="config">
      <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
        {{ snackbarText }}
      </v-snackbar>

    <v-card class="mx-auto my-12" max-width="80%">
      <v-card-title>Twitter Account Linking / アカウント連携</v-card-title>
      <v-card-subtitle>Twitterアカウントとの連携を行います。</v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-form class="d-flex flex-row" ref="oauthtwitter">
            <div v-if="twitter_screen_name" class="body-1">
              Link with / 連携済み: {{ twitter_screen_name }}
            </div>
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="2" @click.stop="oauthtwitter()">Link</v-btn>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="twitter_dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>Twitter Account Linking / アカウント連携</v-card-title>
          <v-card-text>
            <ol>
              <li>Twitterの<a :href="endpoint" target="_blank">アプリケーション認証ページ</a>を開いて、連携アプリを認証してください。</li>
              <li>表示されたPINコードを入力して、連携ボタンをクリックしてください。</li>
            </ol>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field class="pa-2" v-model="pincode" length="16" label="PINコード"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="twitter_dialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="registpin()">Link</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-card class="mx-auto my-12" max-width="80%">
      <v-card-title>Change password / パスワード変更</v-card-title>
      <v-card-subtitle>ログインパスワードを変更します。</v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-form class="d-flex flex-row" ref="changepassword">
            <v-spacer></v-spacer>
            <v-btn color="primary" elevation="2" @click.stop="change_password_dialog = true">Change</v-btn>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="change_password_dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>Change password / パスワード変更</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field class="pa-2" v-model="old_password" length="16" type="password" label="Current password / 現在のパスワード"></v-text-field>
                  <v-text-field class="pa-2" v-model="new_password" length="16" type="password" label="New password / 新しいパスワード"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="change_password_dialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="change_password()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>
export default {
  name: 'Config',
  data() {
    return {
      old_password: '',
      new_password: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      twitter_dialog: false,
      endpoint: '',
      oauth_token: '',
      pincode: '',
      twitter_screen_name: '',
      change_password_dialog: false
    }
  },
  mounted: function() {
    this.get_screen_name();
  },
  methods: {
    get_screen_name: function () {
      var self = this;
      (async() => {
        var res = await self.axios.get(self.$backEnd + '/api/v1/twitter/get_screen_name');
        if (res.data.result) {
          self.twitter_screen_name = res.data.screen_name;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
      });
    },
    oauthtwitter: function () {
      this.snackbar = false;
      this.signinError = false;
      var self = this;
      if (this.$withoutServer) {
          self.twitter_dialog = true;
          return;
      }
      (async() => {
        var res = await self.axios.get(self.$backEnd + '/api/v1/twitter/request_token');
        if (res.data.result) {
          self.endpoint = res.data.endpoint;
          self.oauth_token = res.data.oauth_token;
          self.twitter_dialog = true;
        }
        else {
          self.snackbarText = 'Twitterへのリクエストに失敗しました。';
          self.snackbar = true;
          self.signinError = true;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
        self.signinError = true;
      });
    },
    registpin: function() {
      this.snackbar = false;
      this.signinError = false;
      var self = this;
      if (this.$withoutServer) {
          self.twitter_dialog = false;
          return;
      }
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/twitter/set_access_token', {
          oauth_token: self.oauth_token, oauth_verifier: self.pincode
        }, {
        headers: {
          "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
        }
        });
        if (res.data.result) {
          this.get_screen_name();
          self.is_twitter_connected = true;
          self.twitter_dialog = false;
        }
        else {
          self.snackbarText = '連携に失敗しました。';
          self.snackbar = true;
          self.signinError = true;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
        self.signinError = true;
      });
    },
    change_password: function () {
      this.snackbar = false;
      this.signinError = false;
      var self = this;
      if (this.$withoutServer) {
          self.change_password_dialog = false;
          return;
      }
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/change_password', {
          password_old: self.old_password, password_new: self.new_password
        }, {
        headers: {
          "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
          }
        });
        if (res.data.result) {
          self.snackbarText = 'パスワードを変更しました。';
          self.old_password = '';
          self.new_password = '';
          self.snackbar = true;
          self.change_password_dialog = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.signinError = true;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
      });
    }
  }
}
</script>