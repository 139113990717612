<template>
  <div name="kshaperesult">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
            Training Result
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <img :src="$backEnd + '/api/v1/files/' + id + '/output_train.png'" class="resizeimg"/>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="eli5">
        <v-expansion-panel-header>
            ELI5
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-title>
              Importance Gain
            </v-card-title>
            <v-card-text>
              <img :src="$backEnd + '/api/v1/files/' + id + '/output_importance_gain.png'" class="resizeimg"/>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>
              Permutation Importance
            </v-card-title>
            <v-card-text>
              <v-container fluid>
              <v-data-table
                :headers="eli5_header"
                :items="eli5_items"
                density="compact"
              ></v-data-table>
              </v-container>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="pdpbox">
        <v-expansion-panel-header>
            PDPBox
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-text>
              <v-select
                  v-model="pdpbox_select"
                  :items="features"
                  item-text="text"
                  item-value="name"
                  label="select feature"
                  dense
                ></v-select>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <img :src="$backEnd + '/api/v1/files/' + id + '/output_pdpbox_target_' + pdpbox_select" class="resizeimg"/>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <img :src="$backEnd + '/api/v1/files/' + id + '/output_pdpbox_predict_' + pdpbox_select" class="resizeimg"/>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <img :src="$backEnd + '/api/v1/files/' + id + '/output_pdpbox_isolate_' + pdpbox_select" class="resizeimg"/>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<style scoped>
.resizeimg {
  max-width: 90vw;
}
</style>

<script>

export default {
  name: 'XaiEli5PdpboxResult',
  components: {
  },
  props: {
    id: String,
    job: Object
  },
  data() {
    return {
      opts: {},
      loading: false,
      snackbar: false,
      snackbarText: false,
      snackbarColor: null,
      share_dialog: null,
      files: {},
      log: '',
      panel: [1],
      data: null,
      eli5: null,
      eli5_items: [],
      eli5_header: [
        { text: 'feature', value: 'feature'},
        { text: 'weight', value: 'weight'},
        { text: 'std', value: 'std'},
      ],
      pdpbox_select: null,
      features: [],
    }
  },
  mounted: async function() {
    this.opts = JSON.parse(this.job.opts);
    this.data = await this.get_data();
    if (this.data.eli5) {
      this.eli5 = this.data.eli5;
      this.eli5_items = this.data.eli5.permutation_importance;
    }
    if (this.data.pdpbox) {
      this.pdpbox = this.data.pdpbox;
      this.features = [];
      for (var i = 0; i < this.data.feature_cols.length; i++) {
        this.features.push({
          text: this.data.feature_cols[i],
          name: this.pdpbox.feature_exts[i]
        })
      }
    }
  },
  computed: {
    share_url: function() {
      return this.job.share_id ? (window.location.origin + '/share/' + this.job.share_id) : ''
    }
  },
  watch: {
    panel: function(n, o) {
      if ((n.includes(0)) && (! o.includes(0))) {
        //this.$refs.scatter.redraw();
      }
    }

  },
  methods: {
    update_features: function() {
    },
    get_data: async function() {
      var id = this.id;
      this.loading = true;
      this.loading_text = "Loading";
      var self = this;
      let res;
      try {
        res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.json', {
          transformResponse: [function (data) {
            return data;
          }],
          responseType: 'text',
          onDownloadProgress: async (progressEvent) => {
            if (progressEvent.total) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              self.loading_percent = percentCompleted;
            }
          }
        })
      }
      catch(e) {
        this.snackbarText = res.data.message;
        this.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
      if (res.status == 200) {
        self.loading_percent = null;
        this.loading_text = "Parsing";
        let intid = setInterval(() => {this.loading_text += '.'}, 3000);
        let data = await new Promise(resolve => this.$worker.run((arg) => JSON.parse(arg), [res.data]).then(resolve));
        clearInterval(intid);
        this.loading_percent = null;
        this.loading = false;
        return data;
      }
      else {
        this.snackbarText = res.data.message;
        this.snackbar = true;
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
    }
  }
}
</script>
