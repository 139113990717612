const state = {
    menu_disp: false,
    menu_item: ''
}

const getters = {
    menu_disp: state => state.menu_disp,
    menu_item: state => state.menu_item,
}

const actions = {
    display_menu({ commit}) {
        commit('display_menu');
    },
    hide_menu({ commit }) {
        commit('hide_menu');
    },
    set_menu({ commit }, item) {
        commit('set_menu', item);
    }
}
  
const mutations = {
    display_menu (state) {
        state.menu_disp = true;
    },
    hide_menu (state) {
        state.menu_disp = false;
    },
    set_menu (state, item) {
        state.menu_item = item;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}