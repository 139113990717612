<template>
  <div name="kshaperesult">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-if="warnings">
        <v-expansion-panel-header>
            Warnings
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row v-for="warn in warnings" :key="warn.index">
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
              {{ warn }}
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
            Training Result
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <div v-if="line_chart_data" style="position: relative;">
                  <line-chart :chart-data="line_chart_data" :options="line_chart_options" :height="chart_height"/>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

    <v-expansion-panel>
        <v-expansion-panel-header>
            TimeSeries SHAP Values
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="item_select"
                  :items="items"
                  item-text="name"
                  item-value="index"
                  :label="item_name"
                  @change="draw_bar_chart(item_select)"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex"
                cols="6"
                sm="6"
              >
                <div v-if="bar_chart_data" style="position: relative;">
                  <bar-chart :chart-data="bar_chart_data" :options="bar_chart_options" :height="bar_chart_height" :width="640"/>
                </div>
              </v-col>
              <v-col
                class="d-flex"
                cols="6"
                sm="6"
              >
                <div v-if="bar_chart_rate_data" style="position: relative;">
                  <bar-chart :chart-data="bar_chart_rate_data" :options="bar_chart_options" :height="bar_chart_height" :width="640"/>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import { throws } from 'assert';
import LineChart from '@/components/LineChart'
import BarChart from '@/components/BarChart'


export default {
  name: 'SHAPResult',
  components: {
    LineChart,
    BarChart
  },
  props: {
    id: String,
    job: Object
  },
  data() {
    return {
      opts: {},
      loading: false,
      snackbar: false,
      snackbarText: false,
      snackbarColor: null,
      share_dialog: null,
      files: {},
      log: '',
      panel: [1],
      shap_data: null,
      warnings: [],
      features: [],
      item_name: null,
      items: [],
      item_select: null,
      timeseries: [],
      heatmap_select: null,
      chart_height: 300,
      bar_chart_height: 480,
      line_chart_data: null,
      line_chart_options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: 'black'
          }
        },
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                enabled: true,
                mode: 'x'
            }
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              fontColor: 'black'
            }
          }],
          yAxes: [{
            ticks: {
              fontColor: 'black'
            }
          }]
        }
      },
      bar_chart_data: null,
      bar_chart_rate_data: null,
      bar_chart_options: {
        responsive: true,
        legend: {
          labels: {
            fontColor: 'black'
          }
        },
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'x'
            },
            zoom: {
                enabled: true,
                mode: 'x'
            }
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontColor: 'black'
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              fontColor: 'black'
            }
          }]
        }
      }
    }
  },
  mounted: async function() {
    this.opts = JSON.parse(this.job.opts);
    this.shap_data = await this.get_data();
    if (this.shap_data) {
      this.warnings = this.shap_data.warnings;
      this.draw_best_scores(this.shap_data);
      this.item_name = this.shap_data.name;
      this.features = this.shap_data.feature_cols;
      this.timeseries = this.shap_data.sheets.map(x => x.name);
      this.items = this.shap_data.sheets[0].data[this.item_name].map(function(x, i) { return {'name': x, 'index': i}});
      //this.update_features();
    }
  },
  computed: {
    share_url: function() {
      return this.job.share_id ? (window.location.origin + '/share/' + this.job.share_id) : ''
    }
  },
  watch: {
    panel: function(n, o) {
      if ((n.includes(0)) && (! o.includes(0))) {
        //this.$refs.scatter.redraw();
      }
    }

  },
  methods: {
    update_heatmap: function() {

    },
    update_features: function() {
      var cols = this.shap_data.feature_cols;
      this.features = [];
      var index = 0;
      for (var i = 0; i < cols.length - 1; i++) {
        for (var j = i + 1; j < cols.length; j++) {
          this.features.push({ index: index++, x: i, y: j, xcol: cols[i], ycol: cols[j], name: cols[i] + '_' + cols[j], text: cols[i] + ' - ' + cols[j]})
        }
      }
      this.heatmap_select = this.features[0].name;
    },
    draw_best_scores: function(data) {
      if (data.sheets) {
        var labels = [];
        var dataset = [];
        data.sheets.forEach((e) => {
          labels.push(e.name);
          dataset.push(e.score);
        });
        var datasets = [];
        datasets.push({
          type: 'line', fill: false, lineTension: 0,
          label: 'bestscore', data: dataset,
          borderColor: 'rgba(255, 64, 64, 1.0)'
        });
        this.line_chart_data = { 'labels': labels, 'datasets': datasets };
        console.log(this.line_chart_data);
      }
    },
    draw_bar_chart: function(index) {
      var datasets = [];
      var datasets_rate = [];
      var labels = [];
      this.features.forEach((f) => {
        datasets.push({
          label: f,
          data: [],
          categoryPercentage: 0.5,
          barPercentage: 1,
        })
        datasets_rate.push({
          label: f,
          data: [],
          categoryPercentage: 0.5,
          barPercentage: 1,
        })
      })
      this.shap_data.sheets.forEach((s) => {
        if ((s.shap) && (Object.keys(s.shap).length)) {
          labels.push(s.name);
          var sum = 0.0;
          this.features.forEach((f) => {
            sum += Math.abs(s.shap[f + '_shapvalue'][index]);
          });
          this.features.forEach((f, i) => {
            datasets_rate[i].data.push(Math.abs(s.shap[f + '_shapvalue'][index]) / sum);
            datasets[i].data.push(s.shap[f + '_shapvalue'][index]);
          });
        }
      });
      this.bar_chart_data = {'labels': labels, 'datasets': datasets};
      this.bar_chart_rate_data = {'labels': labels, 'datasets': datasets_rate};
    },
    get_data: async function() {
      var id = this.id;
      this.loading = true;
      this.loading_text = "Loading";
      var self = this;
      let res;
      try {
        res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.json', {
          transformResponse: [function (data) {
            return data;
          }],
          responseType: 'text',
          onDownloadProgress: async (progressEvent) => {
            if (progressEvent.total) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              self.loading_percent = percentCompleted;
            }
          }
        })
      }
      catch(e) {
        this.snackbarText = res.data.message;
        this.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
      if (res.status == 200) {
        self.loading_percent = null;
        this.loading_text = "Parsing";
        let intid = setInterval(() => {this.loading_text += '.'}, 3000);
        let data = await new Promise(resolve => this.$worker.run((arg) => JSON.parse(arg), [res.data]).then(resolve));
        clearInterval(intid);
        this.loading_percent = null;
        this.loading = false;
        return data;
      }
      else {
        this.snackbarText = res.data.message;
        this.snackbar = true;
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
    }
  }
}
</script>
