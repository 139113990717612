<template>
  <div class="analysis">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div class="d-flex justify-space-between">
      <div class="pa-3"><span class="font-weight-bold mr-3">Job Status</span>Total: {{jobs_count}} / Finished: {{jobs_finished}} / Waiting: {{jobs_waiting}}</div>
      <v-dialog v-model="add_job_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"><v-icon>mdi-playlist-plus</v-icon>Add Analysis</v-btn>
        </template>
        <v-card>
          <v-form v-model="form_valid">
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="add_job_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Analyse</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="10" md="10">
                    <v-text-field id="ItemName" v-model="job_item.name" label="Name / 名称" :rules="name_rules"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-select
                      v-model="job_item.command"
                      :items="command_list"
                      item-text="name"
                      item-value="code"
                      label="Method / 処理方法"
                      persistent-hint
                      return-object
                      @change="function() {if (! is_tokenize(job_item.command.code)) { job_item.is_file = true; }}"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row v-if="is_tokenize(job_item.command.code)">
                  <v-col>
                    <v-radio-group v-model="job_item.is_file" mandatory row>
                      <v-radio label="Query / 検索結果" :value="false"></v-radio>
                      <v-radio label="Upload / アップロード" :value="true"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="10">
                    <v-card v-if="(! job_item.is_file) && (is_tokenize(job_item.command.code))" outlined>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="job_item.search_item"
                              :hint="job_item.search_item ? `${lang_dict[job_item.search_item.lang]}` : ''"
                              :items="search_list"
                              item-text="query"
                              item-value="id"
                              label="Item / 収集項目"
                              :rules="search_item_rules"
                              persistent-hint
                              return-object>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <datepicker v-model="job_item.start_date" name="start_date" label="start date / 開始日"/>
                          </v-col>
                          <v-col cols="6">
                            <datepicker v-model="job_item.end_date"  name="end_date" label="end date / 終了日"/>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-card v-else outlined>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-file-input
                              @change="file_input"
                              :rules="file_rules"
                              truncate-length="30"
                              label="Saved file / 解析対象ファイル"
                              :accept="accept_ext(job_item.command.code)"
                              show-size>
                            </v-file-input>
                          </v-col>
                        </v-row>

                        <template v-if="is_tokenize(job_item.command.code)">
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="4">
                              <v-radio-group v-model="job_item.file_type" mandatory row>
                                <v-radio label="JSON" value='json'></v-radio>
                                <v-radio label="CSV" value='csv'></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col><v-text-field v-model="job_item.id_field" label="ID field" hint="IDフィールド名(空白時自動)"></v-text-field></v-col>
                            <v-col><v-text-field v-model="job_item.text_field" label="Text field" hint="テキストフィールド名(空白時自動)"></v-text-field></v-col>
                            <v-col><v-text-field v-model="job_item.date_field" label="Date field" hint="日時フィールド名(空白時自動)"></v-text-field></v-col>
                            <v-col><v-text-field v-model="job_item.name_field" label="Name field" hint="投稿者フィールド名(空白時自動)"></v-text-field></v-col>
                          </v-row>
                        </template>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row outlined v-if="is_tokenize(job_item.command.code)">
                  <v-col>
                    <v-card>
                      <v-card-subtitle>Tokenizer</v-card-subtitle>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-radio-group v-model="job_item.tokenizer_opts.tokenizer" mandatory row>
                                <v-radio label="MeCab (Japanese)" value="mecab"></v-radio>
                                <v-radio label="NLTK (English)" value="nltk"></v-radio>
                                <v-radio label="TreeTagger (English)" value="treetagger"></v-radio>
                                <v-radio label="TreeTagger (Eng, SNSLDA compat)" value="ttcompat"></v-radio>
                                <v-radio label="none (space separated)" value="none"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="3">
                              <v-checkbox v-model="job_item.tokenizer_opts.bigram" label="Bi-gram / 2語連接"
                                @change="() => {if (job_item.tokenizer_opts.bigram & job_item.tokenizer_opts.trigram) { job_item.tokenizer_opts.trigram = false; }}"
                              ></v-checkbox></v-col>
                            <v-col cols="3"><v-checkbox v-model="job_item.tokenizer_opts.trigram" label="Tri-gram / 3語連接"
                                @change="() => {if (job_item.tokenizer_opts.bigram & job_item.tokenizer_opts.trigram) { job_item.tokenizer_opts.bigram = false; }}"
                            ></v-checkbox></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="5"><v-checkbox v-model="job_item.tokenizer_opts.allow_particle" label="Allow Particle / 助詞を挟む連結を許容"
                                :disabled="! (job_item.tokenizer_opts.bigram || job_item.tokenizer_opts.trigram)"
                            ></v-checkbox></v-col>
                            <v-col cols="5"><v-checkbox v-model="job_item.tokenizer_opts.sort" label="Sort compound / 連接時のソート"
                                :disabled="! (job_item.tokenizer_opts.bigram || job_item.tokenizer_opts.trigram)"
                            ></v-checkbox></v-col>
                          </v-row>

                          <v-row>
                            <v-col><v-checkbox v-model="job_item.tokenizer_opts.noun" label="noun only / 名詞のみ"></v-checkbox></v-col>
                            <v-col><v-checkbox v-model="job_item.tokenizer_opts.no_punctuation" label="no punctuation / 記号・接続詞・前置詞等の除去"></v-checkbox></v-col>
                          </v-row>
                          <v-row>
                            <v-col><v-checkbox v-model="job_item.tokenizer_opts.no_url" label="no URL / URL除去"></v-checkbox></v-col>
                            <v-col><v-checkbox v-model="job_item.tokenizer_opts.lemmatize" label="lemmatize / 見出し語化"></v-checkbox></v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card outlined>
                      <lda v-if="job_item.command.code == 'lda'" v-model="opts_lda"></lda>
                      <doc2vec v-else-if="job_item.command.code == 'doc2vec'" v-model="opts_doc2vec"></doc2vec>
                      <kshape v-else-if="job_item.command.code == 'kshape'" v-model="opts_kshape"></kshape>
                      <shap v-else-if="job_item.command.code == 'shap'" v-model="opts_shap"></shap>
                      <shapts v-else-if="job_item.command.code == 'shapts'" v-model="opts_shapts"></shapts>
                      <xai_eli5pdpbox v-else-if="job_item.command.code == 'xai_eli5pdpbox'" v-model="opts_xai_eli5pdpbox"></xai_eli5pdpbox>
                      <xai_skater v-else-if="job_item.command.code == 'xai_skater'" v-model="opts_xai_skater"></xai_skater>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn color="green" class="ma-2" elevation="2" text @click="add_job_dialog = false">Cancel</v-btn>
                    <v-btn color="primary" class="ma-2" elevation="2" text @click="add_job()" :disabled="! form_valid">Analyse</v-btn>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </div>

    <v-data-table
      :headers="is_admin ? headers_admin : headers_user"
      :items="jobs"
      :options.sync="jobs_options"
      :server-items-length="jobs_count"
      class="elevation-1">

      <template v-slot:[`item.cmd`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              {{ item.cmd }}
            </v-btn>
          </template>
          <span v-html="make_option_str(item)"></span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.finished_at`]="{ item }">
        <span v-if="item.finished_at">
          {{ item.finished_at }}
        </span>
        <span v-else-if="item.started_at" class="grey--text">
          {{ item.started_at }}
        </span>
        <v-chip v-else color="orange">
          waiting
        </v-chip>
      </template>

      <template v-slot:[`item.retval`]="{ item }">
        <v-chip v-if="(item.retval === null) && (item.started_at)" color="yellow">
          running
        </v-chip>
        <v-chip v-else-if="item.retval === null" color="orange">
          waiting
        </v-chip>
        <v-chip v-else-if="item.retval === 0" color="green">
          ready
        </v-chip>
        <v-chip v-else color="red" class="white--text">
          error
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          color="primary"
          @click="$router.push({ path: `/result/${item.id}` }) "
          :disabled="(! item.finished_at) && (! item.started_at)"
        >
          <v-icon>mdi-magnify-plus</v-icon>
        </v-btn>
        <v-btn
          icon
          :color="item.share_id ? 'primary' : 'secondary'"
          @click="share_job_dialog(item)"
        >
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          @click="delete_job_dialog(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-dialog v-model="delete_dialog" max-width="480px">
      <v-card>
        <v-card-title class="headline grey lighten-2">Delete Job</v-card-title>
        <v-card-subtitle>{{ delete_item ? delete_item.name : ''}}</v-card-subtitle>
        <v-card-text>削除してもよろしいですか？</v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="green" class="ma-2" elevation="2" text @click="delete_dialog = false">Cancel</v-btn>
          <v-btn color="primary" class="ma-2" elevation="2" text @click="delete_job(delete_item)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="share_dialog" max-width="480px">
      <v-card>
        <v-card-title class="headline grey lighten-2">Share result</v-card-title>
        <v-card-text>
          <v-textarea
          id="share_url"
          outlined
          readonly
          :value="share_url"
          ></v-textarea>
          <div>
            結果ページを他の人と共有します。<br/>
            'NEW URL'で、新しいURLを生成します。このURLを知った人は、システムにログインしなくとも、結果ページにアクセスできます。
            既にURLが生成されている時に、新しいURLを生成すると、古いURLは無効になります。<br/>
            'RESET'で、共有を停止します。<br/>
            'COPY'で、URLをクリップボードにコピーします。
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="create_share_id(share_item)">
            New URL
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="delete_share(share_item)">
            Reset
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="copy_url">
            Copy
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="share_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-fab-transition>
      <v-btn color='pink' fab small dark fixed bottom right @click.stop="refresh()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>
</template>

<script>
import datepicker from '@/components/datepicker'
import lda from '@/components/lda'
import doc2vec from '@/components/doc2vec'
import kshape from '@/components/kshape'
import shap from '@/components/shap'
import shapts from '@/components/shapts'
import xai_eli5pdpbox from '@/components/xai_eli5pdpbox'
import xai_skater from '@/components/xai_skater'
import NotFoundVue from './NotFound.vue'

export default {
  name: 'Analysis',
  components: {
    datepicker,
    lda,
    doc2vec,
    kshape,
    shap,
    shapts,
    xai_eli5pdpbox,
    xai_skater
  },
  data() {
    return {
      name_rules: [
        value => (!! value) || "Name must be set. / 名称は必須項目です。"
      ],
      file_rules: [
        value => (! this.job_item.is_file) || (!! value) || "File must be set. / ファイルが指定されていません。"
      ],
      search_item_rules: [
        value => (this.job_item.is_file) || (!! value) || "Item must be set. / 収集項目が指定されていません。"
      ],
      snackbar: false,
      snackbarText: null,
      snackbarColor: null,
      add_search_dialog: false,
      jobs: [],
      is_admin: false,
      jobs_count: 0,
      jobs_finished: 0,
      jobs_waiting: 0,
      loading: true,
      jobs_page_num: 0,
      form_valid: false,
      delete_dialog: false,
      delete_item: null,
      headers_user: [
          { text: 'ID', align: 'end', value: 'id' },
          { text: 'Name / 名称', align: 'start', value: 'name' },
          { text: 'Registered / 登録時刻', align: 'start', value: 'created_at' },
          { text: 'Command / 処理', align: 'start', value: 'cmd' },
          { text: 'Status / 状況', align: 'start', value: 'retval' },
          { text: 'Finished / 終了時刻', align: 'start', value: 'finished_at' },
          { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      headers_admin: [
          { text: 'ID', align: 'end', value: 'id' },
          { text: 'User / ユーザー名', align: 'start', value: 'user_name' },
          { text: 'Name / 名称', align: 'start', value: 'name' },
          { text: 'Registered / 登録時刻', align: 'start', value: 'created_at' },
          { text: 'Command / 処理', align: 'start', value: 'cmd' },
          { text: 'Status / 状況', align: 'start', value: 'retval' },
          { text: 'Finished / 終了時刻', align: 'start', value: 'finished_at' },
          { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      lang_select: this.$util.get_lang_select(),
      lang_dict: this.$util.get_lang_dict(),
      add_job_dialog: false,
      command_list: this.$util.get_command_list(),
      job_select: null,
      job_item: {
        name: '',
        command: this.$util.get_command_list()[0],
        tokenizer_opts: {
          tokenizer: 'mecab',
          noun: true,
          no_punctuation: true,
          no_url: true,
          lemmatize: false,
          bigram: true,
          trigram: false,
          allow_particle: true,
          sort: false
        },
        opts: null,
        is_file: false,
        file: null,
        file_type: null,
        search_item: null,
        start_date: null,
        end_date: null,
        id_field: '',
        text_field: '',
        date_field: '',
        name_field: ''
      },
      opts_lda: {},
      opts_doc2vec: {},
      opts_kshape: {},
      opts_shap: {},
      opts_shapts: {},
      opts_xai_eli5pdpbox: {},
      opts_xai_skater: {},
      job_name_generated: '',
      search_list: [],
      share_dialog: false,
      share_item: null
    }
  },
  mounted() {
    this.refresh();
  },
  watch : {
    job_item: {
      handler() {
        this.set_job_name();
      },
      deep: true
    },
    opts_kshape: {
      handler() {
        this.set_job_name();
      },
      deep: true
    }
  },
  computed: {
    jobs_options: {
      get() {
       return this.$store.getters.jobs_options;
      },
      set(options) {
        this.$store.dispatch('set_jobs_option', options);
        this.get_job_list();
      }
    },
    share_url: function() {
      if (this.share_item) {
        return this.share_item.share_id ? (window.location.origin + '/share/' + this.share_item.share_id) : ''
      }
      return '';
    }
  },
  methods: {
    refresh: function () {
      this.get_job_list();
      this.get_search_list();
    },
    file_input: function(e) {
      this.job_item.file = e;
      if (e) {
        const ext = e.name.match(/([^.]+)$/)[1];
        console.log(ext);
        if (ext.toLowerCase() == 'json') {
          this.job_item.file_type = 'json';
        }
        if (ext.toLowerCase() == 'csv') {
          this.job_item.file_type = 'csv';
        }
        if (ext.toLowerCase() == 'xlsx') {
          this.job_item.file_type = 'xlsx';
        }
      }
      this.set_job_name();
      return true;
    },
    is_tokenize: function(cmd) {
      switch (cmd) {
        case 'lda':
        case 'doc2vec':
          return true;
        default:
          return false;
      }
    },
    accept_ext: function(cmd) {
      switch (cmd) {
        case 'lda':
        case 'doc2vec':
          return '.csv,.json';
        case 'shap':
          return '.csv,.xlsx';
        case 'shapts':
          return '.xlsx';
        default:
          return 'csv';
      }
    },
    set_job_name: function() {
      if ((this.job_item.name == this.job_name_generated) || (! this.job_item.name)) {
        this.job_name_generated = '';
        if (this.job_item.is_file) {
          if (this.job_item.file) {
            this.job_name_generated = this.job_item.file.name.match(/([^/]*)\./)[1];
          }
          else {
            this.job_name_generated = '';
          }
        }
        else {
          if (this.job_item.search_item) {
            this.job_name_generated = this.job_item.search_item.query + (this.job_item.search_item.lang ? ('_' + this.job_item.search_item.lang) : '');
          }
          else {
            this.job_name_generated = '';
          }
        }
        if ((this.job_name_generated) && (this.job_item.command)) {
          if ((this.job_item.command.code == 'kshape') && (this.opts_kshape.method)) {
            this.job_name_generated += '_' + this.opts_kshape.method;
          }
          else {
            this.job_name_generated += '_' + this.job_item.command.code;
          }
        }
        this.job_item.name = this.job_name_generated;
      }
    },
    get_job_list: function() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.$store.getters.jobs_options;
      var self = this;
      if (this.$withoutServer) {
        self.loading = false;
        return;
      }
      self.loading = true;
      (async() => {
        var params = {}
        if (itemsPerPage > 0) {
          params['limit'] = itemsPerPage;
          if (page) {
            params['offset'] = itemsPerPage * (page - 1);
          }
        }
        if (sortBy) {
          params.sort = (sortDesc ? '-' : '') + sortBy;
        }
        var res = await self.axios.get(self.$backEnd + '/api/v1/job/get_job_list', { params: params });
        if (res.data.result) {
          self.jobs = res.data.list;
          self.is_admin = res.data.is_admin;
          self.jobs_count = res.data.count;
          self.jobs_finished = res.data.finished;
          self.jobs_waiting = res.data.waiting;
          self.loading = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
        self.loading = false;
      });
    },
    add_job: function() {
      if (this.$withoutServer) {
        return;
      }
      let formData = new FormData();
      if (this.job_item.is_file) {
        formData.append('file', this.job_item.file, this.job_item.file.name);
      }
      if (this.job_item.command.code == 'lda') {
        this.job_item.opts = this.opts_lda;
      }
      else if (this.job_item.command.code == 'doc2vec') {
        this.job_item.opts = this.opts_doc2vec;
      }
      else if (this.job_item.command.code == 'kshape') {
        this.job_item.opts = this.opts_kshape;
      }
      else if (this.job_item.command.code == 'shap') {
        this.job_item.opts = this.opts_shap;
      }
      else if (this.job_item.command.code == 'shapts') {
        this.job_item.opts = this.opts_shapts;
      }
      else if (this.job_item.command.code == 'xai_eli5pdpbox') {
        this.job_item.opts = this.opts_xai_eli5pdpbox;
      }
      else if (this.job_item.command.code == 'xai_skater') {
        this.job_item.opts = this.opts_xai_skater;
      }
      formData.append('params', JSON.stringify(this.job_item));
      var self = this;
      self.loading = true;
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/job/add_job', formData, {
          headers: {
            "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
            }
        });
        if (res.data.result) {
          this.get_job_list();
          self.loading = false;
          self.add_job_dialog = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.loading = false;
      });
    },
    delete_job_dialog: function(item) {
      this.delete_item = item;
      this.delete_dialog = true;
    },
    share_job_dialog: function(item) {
      this.share_item = item;
      this.share_dialog = true;
    },
    delete_job: function(item) {
      var self = this;
      self.loading = true;
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/job/delete_job', {
          id: item.id
        }, {
          headers: {
            "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
            }
        });
        if (res.data.result) {
          this.get_job_list();
          self.loading = false;
          self.delete_dialog = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.loading = false;
      });
    },
    get_search_list: function() {
      var self = this;
      if (this.$withoutServer) {
        return;
      }
      self.loading = true;
      (async() => {
        var res = await self.axios.get(self.$backEnd + '/api/v1/gather/get_search_list');
        if (res.data.result) {
          self.search_list = res.data.list;
          self.status = res.data.status;
          self.loading = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
        self.loading = false;
      });
    },
    make_option_str: function(item) {
      if (! item.opts) {
        return '';
      }
      let job = JSON.parse(item.opts);
      if ((job) && (job.opts)) {
        let opt_str = '<span class="subtitle-2">Options</span><br/>';
        Object.keys(job.opts).sort().forEach(function(key) {
          opt_str += '<span class="body-2 ml-2 font-weight-bold">' + key + ':</span>'
                  + '<span class="body-2">' + ((job.opts[key] !== null) ? String(job.opts[key]) : '--') + '</span>';
        });
        if (this.is_tokenize(item.cmd)) {
          opt_str += '<br/><span class="subtitle-2">Tokenize</span><br/>';
          Object.keys(job.tokenizer_opts).sort().forEach(function(key) {
            opt_str += '<span class="body-2 ml-2 font-weight-bold">' + key + ':</span>'
                    + '<span class="body-2">' + ((job.tokenizer_opts[key] !== null) ? String(job.tokenizer_opts[key]) : '--') + '</span>';
          });
        }
        return opt_str;
      }
      return '';
    },
    create_share_id: function(item) {
      var id = item.id;
      if (id) {
        var self = this;
        self.loading = true;
        (async() => {
          var res = await self.axios.get(self.$backEnd + '/api/v1/job/create_share_id', { params: {id: id} });
          if (res.status == 200) {
            item.share_id = res.data.share_id;
          }
          else {
            self.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
            self.snackbar = true;
            self.loading = false;
          }
          self.loading = false;
        })().catch((e) => {
          console.log("caught exception: " + e);
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        });
      }
    },
    delete_share: function(item) {
      var id = item.id;
      if (id) {
        var self = this;
        self.loading = true;
        (async() => {
          var res = await self.axios.get(self.$backEnd + '/api/v1/job/delete_share', { params: {id: id} });
          if (res.status == 200) {
            item.share_id = res.data.share_id;
          }
          else {
            self.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
            self.snackbar = true;
            self.loading = false;
          }
          self.loading = false;
        })().catch((e) => {
          console.log("caught exception: " + e);
          self.snackbarText = 'エラーが発生しました。';
          self.snackbar = true;
          self.loading = false;
        });
      }
    },
    copy_url: function() {
      var elm = document.getElementById('share_url');
      elm.select();
      document.execCommand('copy');
      return;
    }
  }
}
</script>
