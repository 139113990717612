import Vue from 'vue'
import Vuex from 'vuex'
import menu from './store/menu'
import login from './store/login'
import analysis from './store/analysis'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    menu,
    login,
    analysis
  },
  state: {

  },
  mutations: {

  },
  actions: {

  }
})