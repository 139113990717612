const state = {
    is_login: false,
    loginname: ''
}

const getters = {
    is_login: state => state.is_login,
    loginname: state => state.loginname
}

const actions = {
    login({ commit }, name) {
        commit('login', name);
    },
    logout({ commit }) {
        commit('logout');
    },
}

const mutations = {
    login(state, name) {
        state.is_login = true;
        state.loginname = name;
    },
    logout(state) {
        state.is_login = false;
        state.loginname = '';
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

