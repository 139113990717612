import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Cookies from 'js-cookie'
import VueCookies from 'vue-cookies'
import Utility from './utility'
import VueWorker from 'vue-worker'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.use(VueWorker)

var withoutServer = false;
var backEnd = (process.env.NODE_ENV === 'production'? '' : 'http://localhost:5000');

Vue.prototype.$withoutServer = withoutServer;
Vue.prototype.$backEnd = backEnd;
axios.defaults.withCredentials = true;

Vue.prototype.$util = new Utility;

async function set_login_status() {
  // eslint-disable-next-line
  if (withoutServer) {
    if (Cookies.get('login')) {
      store.commit('login', Cookies.get('login'));
    }
  }
  else {
    if (Cookies.get('login')) {
      try {
        await axios.get(backEnd + '/api/v1/refresh_token');
        store.commit('login', Cookies.get('login'));
      }
      catch(e) {
        Cookies.set('login', '', { sameSite: 'strict' });
        store.commit('logout');
        console.log("caught exception: " + e);
      }
    }
    setInterval(() => {
      if (store.getters.is_login) {
        (async() => {
          await axios.get(backEnd + '/api/v1/refresh_token');
        })().catch((e) => {
          console.log("caught exception: " + e);
        });
      }
    }, 5*60*1000);
  }
}


async function main() {
  await set_login_status()
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

main();