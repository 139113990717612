<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field v-model="value.name" label="Company name"
          hint="Field name of NAME field."
          persistent-hint
          class="mt-0 pt-0"
          type="text">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="value.target" label="target"
          hint="Field name of TARGET field."
          persistent-hint
          class="mt-0 pt-0"
          type="text">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="value.param" label="param"
          hint="Field name of PARAM field."
          persistent-hint
          class="mt-0 pt-0"
          type="text">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="value.period" label="period"
          hint="Field name of PERIOD field."
          persistent-hint
          class="mt-0 pt-0"
          type="text">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field v-model="value.drop" label="ROE,ROA,INVratio"
          hint="Comma-separated drop fields."
          persistent-hint
          class="mt-0 pt-0"
          type="text">
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
function defaults() {
  return {
    name: '',
    target: '',
    param: '',
    period: '',
    drop: ''
  }
}
export default {
  props: {
    value: {
      type: Object,
      require: false,
      default: () => defaults()
    }
  },
  data() {
    return {}
  },
  mounted: function() {
    var d = defaults();
    Object.keys(d).forEach(k => { this.value[k] = d[k]});
    console.log(this.value)
  },
  computed: {
  },
  methods: {
  }
}
</script>
