class Utility {
    constructor() {
        this.lang_select = [
            { code: null,	name: 'ALL / 指定しない' },
            { code: 'ja', name: 'Japanese / 日本語' },
            { code: 'en', name: 'English / 英語' },
            { code: 'fr', name: 'French / フランス語' },
            { code: 'it', name: 'Italian / イタリア語' },
            { code: 'es', name: 'Spanish / スペイン語' },
            { code: 'ru', name: 'Russian / ロシア語' },
            { code: 'pt', name: 'Portuguese / ポルトガル語' },
            { code: 'zh', name: 'Chinese / 中国語' },
            { code: 'ko', name: 'Korean / 韓国語' }
        ];
        this.lang_dict = {};
        this.lang_select.forEach(element => {
            this.lang_dict[element.code] = element.name;
        });
        this.command_list = [
            { code: 'lda', name: 'LDA' },
            { code: 'doc2vec', name: 'doc2vec' },
            { code: 'kshape', name: 'k-Shape' },
            { code: 'shap', name: 'SHAP' },
            { code: 'shapts', name: 'SHAP TimeSeries' },
            { code: 'xai_eli5pdpbox', name: 'XAI ELI5 & PDPBox (Regressor)' },
            { code: 'xai_skater', name: 'XAI Skater (Classifier)' },
        ];
    }

    get_lang_select() {
        return this.lang_select;
    }

    get_lang_dict() {
        return this.lang_dict;
    }

    get_command_list() {
        return this.command_list;
    }

    num_format(num, scale) {
        return Number(num).toFixed(scale).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }

    hsv2rgb(h, s, v) {
        var r = v;
        var g = v;
        var b = v;
        if (s > 0.0) {
            h *= 6.0;
            var i = parseInt(h) % 6;
            var f = h - i;
            switch(i) {
                case 0:
                    g *= 1 - s * (1 - f);
                    b *= 1 - s;
                    break;
                case 1:
                    r *= 1 - s * f;
                    b *= 1 - s;
                    break;
                case 2:
                    r *= 1 - s;
                    b *= 1 - s * (1 - f);
                    break;
                case 3:
                    r *= 1 - s;
                    g *= 1 - s * f;
                    break;
                case 4:
                    r *= 1 - s * (1 - f);
                    g *= 1 - s;
                    break;
                case 5:
                    g *= 1 - s;
                    b *= 1 - s * f;
                    break;
                default:
                    g *= 1 - s * (1 - f);
                    b *= 1 - s;
                    break;
            }
            return [parseInt(r*255), parseInt(g*255), parseInt(b*255)];
        }
        else {
            return [parseInt(v*255), parseInt(v*255), parseInt(v*255)];
        }
    }

    index_color(index, num, s=1.0, v=1.0) {
        return this.hsv2rgb(index/num, s, v)
    }
}

export default Utility;
