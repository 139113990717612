<template>
    <v-text-field :name="name" :label="label" v-model="date" :rules="date_rules" hint="YYYY-MM-DD">
    <template v-slot:append-outer>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn icon color="primary" dark elevation="0" v-on="on">
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <v-date-picker class="my-datapicker" v-model="picker" locale="ja-jp" @click="on = false" :day-format="date => new Date(date).getDate()" append-outer/>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      date_str: this.value,
      menu: false,
      date_rules: [
        value => (! value) || (!! value.match(/\d{4}-\d{2}-\d{2}/)) || "Invalid date."
      ],
    };
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        this.menu = false;
        this.date_str = val;
        this.$emit("input", val);
      }
    },
    date: {
      get: function() {
        return this.date_str;
      },
      set: function(val) {
        if (! val) {
          this.date_str = null;
          return null;
        }
        var match = val.match(/^(\d{4})-(\d{2})-(\d{2})$/)
        if (! match) {
          this.date_str = null;
          return null;
        }
        var y = match[1];
        var m = match[2] - 1;
        var d = [3];
        var dt = new Date(y, m, d);
        if (dt.getFullYear() != y || dt.getMonth() != m || dt.getDate() != d){
          this.date_str = null;
          return null;
        }
        this.date_str = dt.toISOString().substr(0, 10);
        return val;
      }
    }
  }
}
</script>

<style scoped>
.my-datapicker >>> .v-date-picker-table--date > table > tbody tr td:nth-child(7) .v-btn__content {
    color:blue
}
.my-datapicker >>> .v-date-picker-table--date > table > tbody tr td:nth-child(1) .v-btn__content {
    color:red
}
</style>