<template>
  <div name="kshaperesult">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card v-if="opts && opts.opts">
      <v-card-text>
        <span class="subtitle-2 mr-6" v-if="opts.opts.method != 'precomputed'">Method: {{ opts.opts.method }}</span>
        <span class="subtitle-2 mr-6">Projection: {{ opts.opts.dr }}</span>
        <span class="subtitle-2 mr-6" v-if="opts.opts.method != 'precomputed'">Period: {{ opts.opts.period == '0' ? '--' : opts.opts.period }}</span>
        <span class="subtitle-2 mr-6" v-if="opts.opts.method != 'precomputed'">Clusters: {{ opts.opts.cluster ? opts.opts.cluster : 'elbow-method' }}</span>
        <span class="subtitle-2 mr-6" v-if="opts.opts.method == 'kmeans'">Metric: {{ opts.opts.metric }}</span>
        <span class="subtitle-2 mr-6" v-if="opts.opts.method == 'kmeans'">Normalized: {{ opts.opts.normalize ? 'Yes' : 'No' }}</span>
      </v-card-text>
    </v-card>
    <v-card v-if="(periods) && (periods.length > 1)">
      <v-card-text>
        <v-row
          class="mb-4"
          justify="space-between"
        >
          <v-col class="text-left">
            <span
              class="text-h5 font-weight-medium"
              v-text="periods[periods_ext].ext"
            ></span>
          </v-col>
        </v-row>
        <v-slider
          v-model="periods_ext"
          :max="periods.length - 1"
          @change="update_graph"
        >
          <template v-slot:prepend>
            <v-icon
              @click="periods_ext--; update_graph();">
              mdi-step-backward
            </v-icon>
          </template>
          <template v-slot:append>
            <v-icon
              @click="periods_ext++; update_graph();">
              mdi-step-forward
            </v-icon>
          </template>
        </v-slider>
      </v-card-text>
    </v-card>

    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
            Projection: {{ opts.opts.dr == 'tsne' ? 'tSNE' : 'UMAP' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col>
                <scatter-chart ref="scatter" id="scatter_chart" :key="periods_ext" :chart-data="scatter_chart_data" :options="scatter_chart_options" :width="640" :height="640"/>
                <div id="scatter-chart-tooltip" :class="$style.scatter_tooltip"></div>
              </v-col>
              <v-col>
                <div>
                  <span v-for="cluster in clusters" :key="cluster.index"
                    class="text-h6 me-5 font-weight-bold" :style="'color:' + get_text_color(cluster.index) +';'"
                  >
                    {{ get_style_char(cluster.index) }}Cluster{{ cluster.index + 1 }}
                  </span>
                </div>
                <div>
                  <v-select
                    v-model="scatter_legend_sort"
                    :items="scatter_legend_sort_items"
                    label="sort"
                    prepend-icon="mdi-sort"
                    dense
                    solo
                    @change="scatter_legend_arrange(scatter_legend_sort)"
                  ></v-select>
                </div>
                <div style="height: 500px; overflow: scroll;">
                  <span v-for="item in period_data" :key="item.index" :id="'scatter_label_' + item.index"
                    class="text-caption me-4 font-weight-medium"
                    :style="'color:' + get_text_color(item.cluster) +'; background-color:' + ((item.index === focused_index) ? '#ffffaa' : '') + ';'"
                    @mouseover="scatter_label_mouseover(item.cluster, item.pos, item.index)"
                    @mouseout="scatter_label_mouseout(item.cluster, item.pos, item.index)"
                  >
                    {{ get_style_char(item.cluster) + item.label }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="opts.opts.method != 'precomputed'">
        <v-expansion-panel-header>
            SBD Distance
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <table :class="$style.sbd_table">
              <thead>
                <tr>
                  <th width="30%" style="cursor: pointer;" @click="sbd_item_sort(-1)">Name</th>
                  <th v-for="cluster in clusters" :key="cluster.index" style="cursor: pointer;" @click="sbd_item_sort(cluster.index)">
                    Cluster {{ cluster.index + 1}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sbd_dists" :key="item.index">
                  <td class="text-caption">{{ item.label }}</td>
                  <td v-for="cluster in clusters" :key="cluster.index">
                    <span
                      :style="'width: ' + item.normdistance[cluster.index] * 100 + '%' + ';'
                        + ((item.cluster == cluster.index) ? ('background-color: ' + get_text_color(cluster.index) + ';') : '')
                        + 'border: 2px solid ' + get_text_color(cluster.index) + ';'
                        + 'height: 8px; display: block;'">
                    </span>
                    <!-- {{ item.normdistance[cluster.index] }} -->
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="opts.opts.method != 'precomputed'">
        <v-expansion-panel-header>
            Chart
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <div v-for="cluster in clusters" :key="cluster.index">
              <h6>Cluster {{ cluster.index + 1}}</h6>
              <line-chart :chart-data="chart_clusters[cluster.index]" :options="chart_options" :height="100"/>
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import router from '@/router'
import ScatterChart from '@/components/ScatterChart'
import LineChart from '@/components/LineChart'
import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';

export default {
  name: 'KshapeResult',
  components: {
    ScatterChart,
    LineChart
  },
  props: {
    id: String,
    job: Object
  },
  data() {
    return {
      opts: {
        opts: {}
      },
      loading: false,
      snackbar: false,
      snackbarText: false,
      snackbarColor: null,
      share_dialog: null,
      files: {},
      log: '',
      graph_data: null,
      periods: [],
      periods_ext: 0,
      period_data: null,
      panel: [0],
      clusters: [],
      scatter_chart_data: {},
      scatter_legend_sort: 'original',
      scatter_legend_sort_items: ['original', 'cluster'],
      focused_index: null,
      sbd_dists: [],
      sbd_sort: -1,
      chart_clusters: [],
      scatter_chart_options: {
        responsive: false,
        animation: {
          duration: 0
        },
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0,
        maintainAspectRatio: true,
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'xy'
            },
            zoom: {
                enabled: true,
                mode: 'xy'
            }
          },
          colorschemes: {
            scheme: null
          }
        },
        tooltips: {
          enabled: false,
          custom: this.scatter_custom_callback,
        },
        legend: {
          display: false
        }
      },
      chart_options: {
        responsive: true,
        animation: {
          duration: 0
        },
        fill: false,
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0,
        maintainAspectRatio: true,
        plugins: {
          zoom: {
            pan: {
                enabled: true,
                mode: 'y'
            },
            zoom: {
                enabled: true,
                mode: 'y'
            }
          },
          colorschemes: {
            scheme: null
          }
        },
        tooltips: {
          enabled: true,
        },
        legend: {
          display: false
        }
      }
    }
  },
  mounted: async function() {
    this.opts = JSON.parse(this.job.opts);
    this.graph_data = await this.get_data();
    console.log(this.opts);
    if (this.graph_data) {
      this.update_graph();
    }
  },
  computed: {
    share_url: function() {
      return this.job.share_id ? (window.location.origin + '/share/' + this.job.share_id) : ''
    }
  },
  watch: {
    panel: function(n, o) {
      if ((n.includes(0)) && (! o.includes(0))) {
        //this.$refs.scatter.redraw();
      }
    }

  },
  methods: {
    get_data: async function() {
      var id = this.id;
      this.loading = true;
      this.loading_text = "Loading";
      var self = this;
      let res;
      try {
        res = await self.axios.get(self.$backEnd + '/api/v1/files/' + id + '/output.json', {
          transformResponse: [function (data) {
            return data;
          }],
          responseType: 'text',
          onDownloadProgress: async (progressEvent) => {
            if (progressEvent.total) {
              let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              self.loading_percent = percentCompleted;
            }
          }
        })
      }
      catch(e) {
        this.snackbarText = res.data.message;
        this.snackbarText = 'エラーが発生しました(status:' + res.statusText + ')。';
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
      if (res.status == 200) {
        self.loading_percent = null;
        this.loading_text = "Parsing";
        let intid = setInterval(() => {this.loading_text += '.'}, 3000);
        let data = await new Promise(resolve => this.$worker.run((arg) => JSON.parse(arg), [res.data]).then(resolve));
        clearInterval(intid);
        this.loading_percent = null;
        this.loading = false;
        return data;
      }
      else {
        this.snackbarText = res.data.message;
        this.snackbar = true;
        this.loading = false;
        this.loading_percent = null;
        this.loading_text = null;
        return;
      }
    },
    get_color: function(i) {
      let scheme = Chart.colorschemes.tableau.Tableau20;
      let color = scheme[i % scheme.length];
      let r = parseInt(color.substr(1, 2), 16);
      let g = parseInt(color.substr(3, 2), 16);
      let b = parseInt(color.substr(5, 2), 16);
      return [r, g, b];
    },
    get_text_color: function(i) {
      let rgb = this.get_color(i);
      return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1.0)`;
    },
    get_style: function(i) {
      let styles = ['circle', 'triangle', 'star', 'rect', 'rectRot'];
      return styles[i % styles.length];
    },
    get_style_char: function(i) {
      let styles = ['●', '▲', '＊', '■', '◆'];
      return styles[i % styles.length];
    },
    update_graph: function() {
      let periods = this.graph_data;
      this.periods = periods;
      if (this.periods_ext < 0) {
        this.periods_ext = 0;
        return;
      }
      if (this.periods_ext >= periods.length) {
        this.periods_ext = periods.length - 1;
        return;
      }
      let period = periods[this.periods_ext];
      let cluster_num = period.cluster_num;
      this.clusters = [];
      for (let i = 0; i < cluster_num; i++) {
        this.clusters.push({index: i});
      }
      let datasets = [];
      for (let i = 0; i < cluster_num; i++) {
        let rgb = this.get_color(i);
        datasets.push({
          label: `Cluster ${i+1}`,
          borderColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1.0)`,
          backgroundColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.8)`,
          data: [],
          pointRadius: 5,
          borderWidth: 2,
          pointStyle: this.get_style(i)
        });
      }
      for (let i = 0; i < cluster_num; i++) {
        let rgb = this.get_color(i);
        datasets.push({
          label: `Centroid ${i+1}`,
          borderColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1.0)`,
          backgroundColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.5)`,
          data: [],
          pointRadius: 8,
          borderWidth:  5,
          hoverRadius: 10,
          hoverBorderWidth: 6,
          pointStyle: this.get_style(i)
        });
      }
      for (let i = 0; i < cluster_num; i++) {
        let rgb = this.get_color(i);
        datasets.push({
          label: `Cluster remark ${i+1}`,
          borderColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1.0)`,
          backgroundColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]},1.0)`,
          data: [],
          pointRadius: 10,
          borderWidth: 2,
          hoverRadius: 10,
          hoverBorderWidth: 6,
          pointStyle: this.get_style(i)
        });
      }
      this.period_data = [];
      this.sbd_dists = [];
      for (let i = 0; i < period.cluster.length; i++) {
        let ds = (('is_centroid' in period) && period.is_centroid[i]) ? period.cluster[i] + cluster_num : period.cluster[i];
        let pos = datasets[ds].data.length;
        datasets[ds].data.push({x: period.proj[i][0], y: period.proj[i][1], index: i})
        if ((! ('is_centroid' in period)) || (! period.is_centroid[i])) {
          this.period_data.push({index: i, label: period.labels[i], pos: pos, cluster: period.cluster[i]});
          if ('sbd'  in period) {
            let distances = [];
            for (let j = period.cluster.length - cluster_num; j < period.cluster.length; j++) {
              distances.push(period.sbd[i][j])
            }
            this.sbd_dists.push({index: i, label: period.labels[i], distances: distances, cluster: period.cluster[i], normdistance: []});
          }
        }
      }
      let max_dist = 0;
      for (let i = 0; i < cluster_num; i++) {
        for (let j = 0; j < this.sbd_dists.length; j++) {
          max_dist = Math.max(max_dist, this.sbd_dists[j].distances[i]);
        }
      }
      for (let i = 0; i < cluster_num; i++) {
        for (let j = 0; j < this.sbd_dists.length; j++) {
          this.sbd_dists[j].normdistance.push(this.sbd_dists[j].distances[i] / max_dist);
        }
      }
      this.scatter_chart_data = { datasets: datasets };
      if (this.scatter_legend_sort != 'original') {
        this.scatter_legend_arrange(this.scatter_legend_sort);
      }
      if (this.sbd_sort != -1) {
        this.sbd_item_sort(this.sbd_sort)
      }
      this.chart_clusters = [];
      for (let i = 0; i < cluster_num; i++) {
        this.chart_clusters.push({type: 'line', labels: period.periodlabels, datasets: []});
      }
      if ('graph' in period) {
        for (let i = 0; i < period.labels.length; i++) {
          let ds = this.chart_clusters[period.cluster[i]];
          let rgb = this.get_color(period.cluster[i]);
          let is_centroid = ('is_centroid' in period) && period.is_centroid[i];
          ds.datasets.push({label: period.labels[i], data: period.graph[i],
            borderColor: `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, ${is_centroid ? 1.0 : 0.4})`,
            borderWidth: is_centroid ? 3 : 1,
            pointRadius: 1,
            pointHoverRadius: 2,
            fill: false, lineTension: 0, backgroundColor: 'rgba(0,0,0,0)'
          });
        }
      }
    },
    scatter_custom_callback: function(tooltipModel) {
      let tooltipEl = document.getElementById('scatter-chart-tooltip');
      if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          this.focused_index = null;
          return;
      }
      if (! tooltipModel.dataPoints) {
        this.focused_index = null;
        return;
      }
      let index = this.scatter_chart_data.datasets[tooltipModel.dataPoints[0].datasetIndex].data[tooltipModel.dataPoints[0].index].index;
      let periods = this.graph_data;
      let period = periods[this.periods_ext]
      let name = period.labels[index];
      tooltipEl.innerHTML = `<div class="subtitle-2">Cluster ${period['cluster'][index] + 1}</div>`;
      if (('is_centeroid' in period) && period['is_centroid'][index]) {
        tooltipEl.innerHTML += `<div class="body">Centeroid</div>`;
      }
      else {
        tooltipEl.innerHTML += `<div class="body">${name}</div>`;
        this.focused_index = index;
      }
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = tooltipModel.caretX + 'px';
      tooltipEl.style.top = tooltipModel.caretY + 'px';
    },
    scatter_label_mouseover: function(cluster, pos, i) {
      this.focused_index = i;
      let datasets = this.scatter_chart_data.datasets;
      let data = datasets[cluster].data[pos];
      datasets[this.clusters.length * 2 + cluster].data = [];
      datasets[this.clusters.length * 2 + cluster].data.push({x: data.x, y: data.y});
      this.$refs.scatter.update();
    },
    scatter_label_mouseout: function(cluster, pos, i) {
      this.focused_index = null;
      let datasets = this.scatter_chart_data.datasets;
      datasets[this.clusters.length * 2 + cluster].data = [];
      this.$refs.scatter.update();
    },
    scatter_legend_arrange: function(method) {
      this.period_data.sort((a, b) => {
        if (method == 'original') {
          if (a.index < b.index) {
            return -1;
          }
          else if (a.index > b.index) {
            return 1;
          }
          else {
            return 0;
          }
        }
        else {
          if (a.cluster < b.cluster) {
            return -1;
          }
          else if (a.cluster > b.cluster) {
            return 1
          }
          else if (a.index < b.index) {
            return -1;
          }
          else if (a.index > b.index) {
            return 1;
          }
          else {
            return 0;
          }
        }
      });
    },
    sbd_item_sort: function(c) {
      this.sbd_sort = c;
      this.sbd_dists.sort((a, b) => {
        if (c < 0) {
          if (a.index < b.index) {
            return -1;
          }
          else if (a.index > b.index) {
            return 1;
          }
          else {
            return 0;
          }
        }
        else {
          if (a.distances[c] < b.distances[c]) {
            return -1;
          }
          else if (a.distances[c] > b.distances[c]) {
            return 1;
          }
          else {
            return 0;
          }
        }
      })
    }
  }
}
</script>

<style module>
.scatter_tooltip {
  position: absolute;
  text-align: left;
  width: 360px;
  padding: 4px;
  font: 12px sans-serif;
  background: #000000c0;
  color: #ffffffff;
  border: 10px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
}
.sbd_table {
  border-collapse: collapse;
}
.sbd_table th {
  color: #ffffff;
  background-color: #888888;
}
.sbd_table td {
  padding-left: 5px;
  padding-right: 5px;
  border-collapse: collapse;
  border: 1px solid #aaaaaa;
}
</style>
