<template>
  <div v-if="opts" class="ml-6 body-2">
    <span class="font-weight-bold">Tokenizer:</span><span class="mr-4">{{ opts.tokenizer }}</span>
    <span class="font-weight-bold">N-Gram:</span><span class="mr-4">{{ opts.bigram ? 'Bi-gram' : (opts.trigram ? 'Tri-gram' : 'No') }}</span>
    <span v-if="opts.bigram || opts.trigram" class="font-weight-bold">N-Gram sort:</span><span class="mr-4">{{ opts.sort ? 'Yes' : 'No' }}</span>
    <span v-if="opts.bigram || opts.trigram" class="font-weight-bold">Allow Particle:</span><span class="mr-4">{{ opts.allow_particle ? 'Yes' : 'No' }}</span>
    <span class="font-weight-bold">Lemmatize:</span><span class="mr-4">{{ opts.lemmatize ? 'Yes' : 'No' }}</span>
    <span class="font-weight-bold">No punctuation:</span><span class="mr-4">{{ opts.punctuation ? 'Yes' : 'No' }}</span>
    <span class="font-weight-bold">No URL:</span><span class="mr-4">{{ opts.no_url ? 'Yes' : 'No' }}</span>
  </div>
</template>

<script>
export default {
  props: {
    opts: Object
  },
  data() {
    return {
    }
  }
}
</script>
