<template>
  <div class="home">
    <v-snackbar :color="snackbarColor" v-model="snackbar" top :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay :value="loading" z-index="1000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div class="d-flex justify-space-between">
      <div class="pa-3"><span class="font-weight-bold mr-3">API Status</span>limit:{{ status.limit }} / remain:{{ status.remain }}</div>
      <v-btn @click.stop="add_search_dialog =true"><v-icon>mdi-playlist-plus</v-icon>Add query</v-btn>
    </div>
    <v-dialog v-model="add_search_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add query</span>
        </v-card-title>
        <v-card-text>
          <v-text-field class="pa-2" v-model="query" label="Query / 検索条件"></v-text-field>
          <v-select
            class="pa-2"
            v-model="lang"
            :items="lang_select"
            item-text="name"
            item-value="code"
            label="Language / 言語"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn @click="add_search_dialog = false">Close</v-btn>
            <v-btn @click="add_search_item()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <draggable v-model="list" draggable=".search-item" handle=".item-handle" @end="sort_end">
      <div v-for="item in list" :key="item.id" class="search-item">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <span><v-icon class="item-handle">mdi-drag</v-icon>{{ item.query }}</span>
            <span class="text-caption text--gray">{{ lang_dict[item.lang] }}</span>
          </v-card-title>
          <v-card-text class="d-flex justify-space-between">
            <div>
              <span v-if="item.paused" class="red white--text">PAUSED</span>
              <span v-else class="green white--text">WORKING</span>
                {{ item.count }} tweets / recent tweet: {{ item.recent_at ? item.recent_at : 'not fetched'}} / created: {{ item.created_at }}
              <span v-if="is_admin">
                / {{ item.user_name }}
              </span>
            </div>
            <div>
              <v-btn @click.stop="view_tweets(item)"><v-icon>mdi-magnify-plus</v-icon></v-btn>
              <v-btn @click.stop="download_tweets(item)"><v-icon>mdi-download</v-icon></v-btn>
              <v-btn @click.stop="set_pause(item, ! item.paused)"><v-icon>{{ item.paused ? 'mdi-play' : 'mdi-pause'}}</v-icon></v-btn>
              <v-btn @click.stop="dialog_item = item; delete_tweets_dialog = true"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </draggable>

    <v-dialog v-model="view_tweets_dialog" scrollable>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Tweets: {{dialog_item ? dialog_item.query : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="view_tweets_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text-center">
          <v-pagination
            v-model="tweets_page"
            :length="tweets_page_num"
            :total-visible="10"
            @input="move_tweets_page()"
          ></v-pagination>
        </div>
        <v-card-text>
          <v-card v-for="tweet in tweets" :key="tweet.id">
            <div class="pa-2">
              <p class="subtitle-1 text--primary mb-1">@{{ tweet.twitter_user_screen_name}}</p>
              <p class="text--primary">{{ tweet.text }}</p>
              <p class="caption text-right">{{ tweet.created_at }}</p>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="download_tweets_dialog" width="400px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Download: {{dialog_item ? dialog_item.query : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="download_tweets_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8">
                <datepicker v-model="start_date" name="start_date" label="start date / 開始日"/>
                <datepicker v-model="end_date"  name="end_date" label="end date / 終了日"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"></v-col>
              <v-col cols="8">
                Format:
                <v-radio-group name="format" v-model="download_format" row>
                  <v-radio label="JSON" value="json"></v-radio>
                  <v-radio label="CSV" value="csv"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="download_tweets_dialog = false">Cancel</v-btn>
          <a :href="$backEnd + '/api/v1/tweets/download_tweets'
            + '?search_id=' + (dialog_item ? dialog_item.id : '') + '&start=' + start_date + '&end=' + end_date + '&format=' + download_format + '&filename=' + download_filename" 
            :download="download_filename" target="_blank">
            <v-btn color="green darken-1" text @click="download_tweets_dialog = false">download</v-btn>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delete_tweets_dialog" width="400px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Delete: {{dialog_item ? dialog_item.query : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="delete_tweets_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8">
                <datepicker v-model="start_date" name="start_date" label="start date / 開始日"/>
                <datepicker v-model="end_date"  name="end_date" label="end date / 終了日"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="delete_entry" label="Delete entry / 項目を削除"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
            <v-alert border="bottom" colored-border type="warning" elevation="2" v-if="(delete_entry) || ((! start_date) && (! end_date))">
              All tweets will be deleted.<br/>全てのツイートが削除されます。
            </v-alert>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="delete_tweets_dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="delete_tweets()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-fab-transition>
      <v-btn color='pink' fab small dark fixed bottom right @click.stop="get_search_list()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>
</template>

<script>
// eslint-disable-next-line
import draggable from 'vuedraggable'
import datepicker from '@/components/datepicker'

var dummy_list = [
  { id: 1, user_id: 1, query: "test query 1", lang: '', max_id: 12345, paused: false, status: "in_action", order: 1, count: 12345, created_at: "2021-01-16 11:50:13", updated_at: "2021-01-16 11:50:13"},
  { id: 2, user_id: 1, query: "test query 2", lang: '', max_id: 12345, paused: false, status: "in_action", order: 2, count: 12345, created_at: "2021-01-16 11:50:13", updated_at: "2021-01-16 11:50:13"},
  { id: 3, user_id: 1, query: "test query 3", lang: '', max_id: 12345, paused: false, status: "in_action", order: 3, count: 12345, created_at: "2021-01-16 11:50:13", updated_at: "2021-01-16 11:50:13"},
  { id: 4, user_id: 1, query: "test query 4", lang: '', max_id: 12345, paused: false, status: "in_action", order: 4, count: 12345, created_at: "2021-01-16 11:50:13", updated_at: "2021-01-16 11:50:13"},
  { id: 5, user_id: 1, query: "test query 5", lang: '', max_id: 12345, paused: false, status: "in_action", order: 5, count: 12345, created_at: "2021-01-16 11:50:13", updated_at: "2021-01-16 11:50:13"},
];

export default {
  name: 'Home',
  components: {
    draggable,
    datepicker
  },
  data() {
    return {
      date_rules: [
        value => (! value) || (!! value.match(/\d{4}-\d{2}-\d{2}/)) || "Invalid date."
      ],
      lang_select: this.$util.get_lang_select(),
      lang_dict: this.$util.get_lang_dict(),
      snackbar: false,
      snackbarText: null,
      snackbarColor: null,
      add_search_dialog: false,
      loading: false,
      list: [],
      status: {},
      query: null,
      lang: null,
      view_tweets_dialog: false,
      tweets: [],
      tweets_page: 1,
      dialog_item: null,
      tweets_par_page: 10,
      tweets_page_num: 0,
      download_tweets_dialog: false,
      start_date: null,
      end_date: null,
      download_format: 'json',
      delete_tweets_dialog: false,
      delete_entry: false,
      is_admin: false
    }
  },
  created() {
    this.lang_dict = {};
    this.lang_select.forEach(element => {
      this.lang_dict[element.code] = element.name;
    });
    this.get_search_list();
  },
  computed: {
    download_filename: function() {
      var marks = { '\\': '￥', '⁄': '⁄', ':': '：', '*': '＊', '?': '？', '"': '”', '>': '＞', '<': '＜', '|': '｜' };
      var name = "TG";
      if (this.dialog_item) {
        var qf = this.dialog_item.query;
        for (var key in marks) {
          qf = qf.replace(key, marks[key]);
        }
        name += '_' + qf
      }
      var dash = false
      if (this.start_date) {
        name += "_" + this.start_date + '-'
        dash = true
      }
      if (this.end_date) {
        if (! dash) {
          name += '_-' + this.end_date
        }
        else {
          name += this.end_date
        }
      }
      name += '.' + this.download_format;
      return name;
    }
  },
  methods: {
    get_search_list: function() {
      var self = this;
      if (this.$withoutServer) {
        this.list = dummy_list;
        return;
      }
      self.loading = true;
      (async() => {
        var res = await self.axios.get(self.$backEnd + '/api/v1/gather/get_search_list');
        if (res.data.result) {
          self.list = res.data.list;
          self.status = res.data.status;
          self.is_admin = res.data.is_admin;
          self.loading = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'エラーが発生しました。';
        self.snackbar = true;
        self.loading = false;
      });
    },
    add_search_item: function() {
      var self = this;
      if (this.$withoutServer) {
        return;
      }
      self.loading = true;
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/gather/add_search_item', {
          query: self.query, lang: self.lang
        }, {
        headers: {
          "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
          }
        });
        if (res.data.result) {
          self.list = res.data.list;
          self.loading = false;
          self.add_search_dialog = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.loading = false;
      });
    },
    delete_tweets: function() {
      var self = this;
      if (this.$withoutServer) {
        return;
      }
      self.loading = true;

      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/gather/delete_tweets', {
          'search_id': self.dialog_item.id,
          'with_entry': self.delete_entry,
          'start': self.start_date,
          'end': self.end_date
        }, {
        headers: {
          "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
          }
        });
        if (res.data.result) {
          self.list = res.data.list;
          self.loading = false;
          self.delete_tweets_dialog = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.loading = false;
      });
    },
    set_pause: function(item, pause) {
      var self = this;
      if (this.$withoutServer) {
        return;
      }
      self.loading = true;
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/gather/set_pause', {
          id: item.id, paused: pause
        }, {
        headers: {
          "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
          }
        });
        if (res.data.result) {
          self.list = res.data.list;
          self.loading = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
      });
    },
    sort_end: function() {
      var self = this;
      if (this.$withoutServer) {
        return;
      }
      self.loading = true;
      (async() => {
        var res = await self.axios.post(self.$backEnd + '/api/v1/gather/set_search_order', {
          ids: this.list.map(item => item.id)
        }, {
        headers: {
          "X-CSRF-TOKEN": self.$cookies.get("csrf_access_token")
          }
        });
        if (res.data.result) {
          self.list = res.data.list;
          self.loading = false;
        }
        else {
          self.snackbarText = res.data.message;
          self.snackbar = true;
          self.loading = false;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.loading = false;
      });
    },
    view_tweets: function(search_item) {
      this.tweets = []
      this.tweets_page = 1;
      this.dialog_item = search_item;
      this.tweets_page_num = Math.ceil(search_item.count / this.tweets_par_page);
      this.view_tweets_dialog = true
      this.get_tweets(this.dialog_item.id, 0, this.tweets_par_page)
    },
    move_tweets_page: function() {
      this.get_tweets(this.dialog_item.id, this.tweets_par_page * (this.tweets_page - 1), this.tweets_par_page)
    },
    get_tweets: function(search_id, offset, limit) {
      var self = this;
      if (this.$withoutServer) {
        this.list = dummy_list;
        return;
      }
      self.loading = true;
      (async() => {
        var params = 'search_id=' + search_id
        params += offset ? ('&offset=' + offset) : ''
        params += limit ? ('&limit=' + limit) : ''
        var res = await self.axios.get(self.$backEnd + '/api/v1/tweets/get_tweets?' + params);
        if (res.data.result) {
          self.tweets = res.data.tweets;
          self.loading = false;
        }
        else {
          self.loading = false;
          self.snackbarText = res.data.message;
          self.snackbar = true;
        }
      })().catch((e) => {
        console.log("caught exception: " + e);
        self.snackbarText = 'Error occurred.';
        self.snackbar = true;
        self.loading = false;
      });
    },
    download_tweets: function(item) {
      this.dialog_item = item;
      this.start_date = null;
      this.end_date = null;
      this.download_format = 'json';
      this.download_tweets_dialog = true;
    }
  }
}
</script>